<template>
    <div class="selection-bar selection-bar--round selection-bar-white">
        <div class="container selection-nav__container">
            <div class="selection-nav">
                <div class="selection-nav__title">Подберите деталь для своего автомобиля</div>
                <div class="selection-nav__list">
                    <p v-for="(tab, index) in tabs" :key="'tab-' + index" :class="['selection-nav__item', { active: tab.active }]" @click="setActiveTab(tab)">
                        {{ tab.title }}
                    </p>
                    <p class="clearButton selection-nav__list__clear" @click="clearInputs">
                        Очистить
                    </p>
                </div>
            </div>

            <div class="selection-container">
                <SelectionInput 
                    v-for="input in inputs"
                    :key="input.id"
                    :is-select-form="input.type === 'select'"
                    :placeholder="input.placeholder"
                    :id="input.id"
                    :api="activeTab.api"
                    :value="input.value"
                    :is-disabled="isInputDisabled(input.requires)"
                    :required-inputs="getRequiredInputs(input.requires)"
                    :default-list="input.defaultList"
                    @get:items="(value) => setInputProperty(input.id, value, 'items')"
                    @get:value="(value) => setInputProperty(input.id, value, 'value')"
                    @action:send="() => getSelection()"
                />

                <div class="selection-container__button uk-inline">
                    <button
                        :class="['button', 'button-md', 'button-primary', 'button-solid', { 'button-disabled': isButtonDisabled }]"
                        @click.prevent="getSelection()"
                    >
                        {{ activeTab.button }}
                    </button>
                    <div
                        v-if="isButtonDisabled"
                        class="selection__dropdown" 
                        uk-dropdown="mode: click"
                    >
                        Заполните пустые поля
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import SelectionInput from "@components/SelectionInput.vue";

const $store = useStore();
const $router = useRouter();

const $props = defineProps({
    mobile: false,
});

const getters = computed(() => {
    return {
        getTabs: $store.getters["selection/getTabs"],
        getTabInputs: $store.getters["selection/getTabInputs"],
        getRequiredInputs: $store.getters["selection/getRequiredInputs"],
        isInputDisabled: $store.getters["selection/isInputDisabled"],
        isButtonDisabled: $store.getters["selection/isButtonDisabled"],
    };
});

const tabs = computed(() => {
    return getters.value.getTabs;
});

const activeTab = computed(() => {
    return tabs.value.find((tab) => tab.active);
});

const inputs = computed(() => {
    return getters.value.getTabInputs;
});

const isButtonDisabled = computed(() => {
    return getters.value.isButtonDisabled(activeTab.value.id, activeTab.value.required);
});

const getRequiredInputs = (requires) => {
    return getters.value.getRequiredInputs(activeTab.value.id, requires);
};

const isInputDisabled = (requires) => {
    return getters.value.isInputDisabled(activeTab.value.id, requires);
};

const setActiveTab = (tab) => {
    if (tab.type === "tab") {
        $store.commit("selection/setActiveTab", { tabId: tab.id });
    }
    if (tab.type === "button") {
        $store.commit("modal/CALL_MODAL", tab.nameForm);
    }
};

const setInputProperty = (inputId, items, property) => {
    $store.commit("selection/setInputProperty", {
        tabId: activeTab.value.id,
        inputId,
        property,
        value: items,
    });
};

const getSelection = () => {
    if (!isButtonDisabled.value && activeTab.value.api) {
        let query = {};
        getRequiredInputs(activeTab.value.required).forEach(element => {
            query[element.id] = element.value;
        });
        $router.push({ path: `/selection/${activeTab.value.api}`, query });
    }
};

function clearInputs() {
    inputs.value[0].value = null
    tabs.value[0].inputs[0].value = null
}

</script>

<style lang="scss" scoped>
.clearButton {
    color: #333333;
    border-bottom: 1px solid #333333;
    cursor: pointer;
    text-align: end;
}

</style>
