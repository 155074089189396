import menuStaticJson from "@assets/static/json/menu.json";
// import categoryListStaticJson from "@assets/static/json/category-list.json";
import marketplaceStaticJson from "@assets/static/json/marketplaces.json";
import adviceFormStaticJson from "@assets/static/json/advice-form.json";
import carouselProductsStaticJson from "@assets/static/json/carousel-products.json";

const state = () => {
    return {
        menu: menuStaticJson,
        // categories: categoryListStaticJson,
        marketplaces: marketplaceStaticJson,
        advice: adviceFormStaticJson,
        carouselProducts: carouselProductsStaticJson,
    };
};

const getters = {
    getMenu: (state) => state.items,
    getCategories: (state) => state.categories,
    getMarketplaces: (state) => state.marketplaces,
    getAdvice: (state) => state.advice,
    gerCarouselProducts: (state) => state.carouselProducts,
};

const mutations = {
    setActiveMenuItem(state, payload) {
        state.menu.forEach((element) => {
            if (element.link == payload.link) {
                element.active = true;
            }
        });
    },
    resetActiveMenuItem(state) {
        state.menu.forEach((element) => {
            element.active = false;
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
