<template>
    <footer class="footer">
        <div class="container footer--container">
            <div class="footer--content">
                <a
                    class="footer--feedback"
                    href="#feedback"
                    @click.prevent="store.commit('modal/CALL_MODAL', 'feedback')"
                >
                    {{ $t("modalFeedback") }}
                </a>

                <p class="footer--address_en">
                    Double Force Technology, Co., Ltd., Maybrook House, 97 Godstone Road, Caterham,
                    Surrey CR3 6RE, United Kingdom
                </p>
                <p class="footer--copyright desktop-ver">
                    © Double Force, Ltd, {{year}} All rights reserved
                </p>
                <p class="footer--address_ru">
                    {{ $t("footerAdressRu") }}
                </p>
                <p class="footer--copyright mobile-ver">
                    © Double Force, Ltd, {{year}} All rights reserved
                </p>
            </div>
        </div>
    </footer>
    <!--<header-alert>
        Перейти на старую версию сайта
        <a href="https://old.doubleforce.ru/">https://old.doubleforce.ru/</a>
    </header-alert>-->
</template>

<script setup>
import { ref } from "vue"; 
import { useStore } from "vuex";
import HeaderAlert from "./HeaderAlert.vue";
const year = ref(new Date().getFullYear());
const store = useStore();
</script>

<style lang="sass" scoped>
@import '@stylebase/variables.sass'
@import '@stylebase/media.sass'
.footer
    position: relative
    max-height: 250px
    min-height: 184px
    display: flex
    align-items: center
    width: 100%
    max-width: 1920px
    bottom: 0
    left: 50%
    transform: translateX(-50%)
    background: $color-white-100
    box-shadow: 0px 10px 8px 0px #3333331A inset
    @include md
        max-height: 384px
        //max-width: 100%
        //padding-bottom: 40px
    &--content
        display: grid
        grid-template-columns: minmax(200px,350px)minmax(374px,550px)
        justify-content: space-between
        //align-items: flex-start
        row-gap: 15px
        column-gap: 10px
        padding-top: 60px
        padding-bottom: 35px
        @include md
            display: flex
            flex-direction: column
            row-gap: 20px
            padding-bottom: 40px
        & a
            white-space: nowrap
            font-size: 18px
            font-weight: 700
            font-family: Europe
            color: #E30613
            position: relative
            //border-bottom: 1px solid transparent
            line-height: 18px
            text-decoration: none !important
            &:before
                content: ""
                position: absolute
                height: 1px
                width: 0%
                background: #E30613 !important
                bottom: 14px
                transition: width .25s ease-in-out
                @include md
                    bottom: -3px
            &:hover
                &::before
                    width: 100%
    &--copyright
        display: flex
        align-items: flex-end
        font-family: Europe
        font-size: 14px
        font-weight: 400
        line-height: 14px
        color: #828282
    &--feedback
        width: fit-content
    &--address_en, &--address_ru
        display: flex
        align-items: flex-end
        font-family: Europe
        font-size: 14px
        font-weight: 400
        line-height: 18px
        color: #333333
    &--address_en
        font-weight: 700

.desktop-ver
    @include md
        display: none

.mobile-ver
    display: none
    @include md
        display: flex
</style>
