<template>
    <!-- <div style="position: fixed; top: 0; z-index: 99999;">{{ scrollY }}</div> -->
    <Header />
    <template v-if="locale === 'ru'">
        <div class="hidden-block whHidden" style="background: transparent;" v-if="scrollY >= 100">{{ locale }}</div>    
    </template>
    
    <div class="selection-block">
        <SelectionBar class="selectionBar" v-if="locale==='ru'" :class="{'positionSeleciton' : scrollY >= 100, 'openSelection' : openSelection}" />
        <button class="selection-block-open" :class="{'pf' : scrollY >= 100}" @click="openSelection = !openSelection" v-if="locale==='ru', openSelection===false">
            <div class="header-butOpen-text">
                Подберите деталь для своего автомобиля
            </div>
            <div class="header-butOpen-arrow">
                <img src="@/assets/img/png/header/arrow.png" alt="arrw">
            </div>
        </button>
        <button class="selection-block-close" @click="openSelection = !openSelection" v-if="locale==='ru', openSelection">
            Закрыть подбор
        </button>
    </div>
    <main id="main" v-scroll="() => globScrollAnimation()">
        <RouterView />
    </main>
    
    <Footer />
    <ArrowUp></ArrowUp>
    <simple-modal :window-small="isModalSmall">
        <FeedbackForm v-if="modal.feedback" :postRequest="feedbackPost" :rowsTextarea="5" :showErrorDetail="true" />
        <MarketplaceForm v-if="modal.marketplace" />
        <AdviceForm v-if="modal.advice" />
    </simple-modal>
</template>

<script setup>
import { computed, ref, onMounted, onUpdated } from "vue";
import { SimpleModal } from "vue3-simple-modal-form";
import { FeedbackForm } from "vue3-usf-feedback-form";
import { useStore } from "vuex";
import { useAnimation } from "@assets/js/animation.js";
import Header from "@components/Header.vue";
import Footer from "@components/Footer.vue";
import MarketplaceForm from "@components/MarketplaceForm.vue";
import AdviceForm from "@components/AdviceForm.vue";
import SelectionBar from "@components/SelectionBar.vue";
import { useI18n } from "vue-i18n";
const { locale } = useI18n();
import ArrowUp from '@/components/ArrowUp.vue'

var cacheChoiseModal = new Object();
const feedbackPost = import.meta.env.VITE_APP_SERVICES_API_URL + "/post-form";
const $store = useStore();

const { globScrollAnimation } = useAnimation()

const modal = computed(() => {
    const value = $store.state.modal.name;
    if (value) {
        cacheChoiseModal = new Object();
        cacheChoiseModal[value] = true;
    }
    return cacheChoiseModal;
});

const isModalSmall = computed(() => {
    return modal.value.marketplace;
});

const scrollY = ref(0); 

const scrollPosition = () => {
  scrollY.value = window.scrollY;
};

onMounted(() => {
    window.addEventListener('scroll', scrollPosition);
});

onUpdated(() => {
    window.addEventListener('scroll', scrollPosition);
});

const openModal = ref(false);

const openSelection = ref(false);

</script>

<style lang="sass" scoped>
.selectionBar
    // position: absolute
    // width: 100%
    // top: 100px
    // // top: 0
    // width: 100%
    z-index: 988
    max-width: 1920px
    // @media screen and (max-width: 1024px)
    //     width: 1038px
    // @media screen and (max-width: 768px)
    //     width: 100%
    @media screen and (max-width: 480px)
        display: none
    //     max-width: 480px
    //     width: 100%
.positionSeleciton
    position: fixed
    top: 0
    width: 100%

.openSelection
    display: flex
    max-width: none
    position: sticky
    top: 0px
    z-index: 999


.topButton
    position: fixed
    // top: 570px
    top: 0

.pf
    position: fixed
    top: 0

.selection-block
    width: 100%
    background: transparent
    position: relative
    z-index: 999
    position: sticky
    top: 0
    @media screen and (max-width: 1024px)
        margin-bottom: -40px
    &-open
        // position: relative
        display: none
        z-index: 989
        @media screen and (max-width: 480px)
            display: flex
        width: 100%
        border-radius: 0 0 25px 25px
        box-shadow: 0px 2px 6px 0px #00000040
        border: none
        background: white
        flex-direction: row
        justify-content: space-between
        padding: 13px 12px
        font-family: Europe
        font-size: 18px
        font-weight: 700
        line-height: 26px
        letter-spacing: 0em
        text-align: left
        color: #1F71B8
        height: 54px
        align-items: center
    &-close
        position: sticky
        display: flex
        justify-content: center
        top: 475px
        width: 100%
        background: white
        border: none
        height: 54px
        z-index: 989
        font-family: Europe
        font-size: 18px
        font-weight: 700
        line-height: 26px
        letter-spacing: 0em
        text-align: left
        color: #1F71B8
        align-items: center
        border-top: 1px solid #333333

.whHidden
    width: 100%
    height: 127.188px
    @media screen and (max-width: 480px)
        height: 60px
</style>