export const en = {
    /** components */
    buttonMore: 'Find out more',
    buttonWheretobuy: 'Stores in your city',
    buttonMarketplace: 'Buy on marketplaces',
    benefitsTitle: "Benefits",
    /** PRODUCT PAGE */
    productHeaderTitle: "Double Force  <br>Products",
    /** HOME PAGE */
    homeAutoParts: 'Auto Parts',
    homeHeadtop: 'DOUBLE FORCE',
    homeHeadmiddle: "REALIABILITY <br> AND QUALITY",
    homeHeadbottom: "FOR YOUR CAR",
    homeBluetext: "DOUBLE FORCE’S <br>OWN ENTERPRISES <br>AND <br> PARTNER FACTORIES",
    homeRighttext: "Accredited according to the quality management <br>standards ISO 9001:2015 or ISO 9004:2009, <br>and also to the industry standard ISO/TS 16949:2009 for OEM products.",
    homeInfotitle:"Double Force trademark  <br>is a property of the English company DFT.",
    homeInfoleft: "Double Force is a car parts brand that <br> provides a range of car<br> parts for Japanese, European, <br> Korean and American cars.",
    homeInforight: "Double Force Technology headquarters <br>is Caterham, UK. Since 2017, <br>the brand has been supplying to Russia a range of products: <br>chassis parts, electrical components, <br>brake and filtration systems, and <br>windshield wiper blades.",
    homeInfonews: "New",
    homeInfoNewsTitle1: "Carbon cabin filters",
    homeInfoNewsTitle2: "DOUBLE FORCE oil filters",
    homeInfoNewsTitle3: "DOUBLE FORCE shock mounts",
    homeInfoNewsText1: "DOUBLE FORCE brand has added a new product to its range – carbon cabin filters.",
    homeInfoNewsText2: "We are pleased to inform you about the arrival of DOUBLE FORCE new products – oil filters for internal combustion engines!", 
    homeInfoNewsText3: "We are pleased to inform you about new products in the DOUBLE FORCE range! DOUBLE FORCE shock mounts are produced...",
    homeInfoBrandTitle: "Become a seller of <br> DOUBLE FORCE brand in your city", 
    homeInfoBrandContent: "Wholesale deliveries of products at manufacturer’s prices <br>to your auto shop, service station or online store. The terms <br>of cooperation are negotiated individually with a personal manager.",
    /** FOOTER */
    footerAdressRu: "Double Force Technology, Co., Ltd., Maybrook House, 97 Godstone Road, Caterham, Surrey CR3 6RE, United Kingdom",
    /** COMPANY PAGE */
    companyHeadTitle: "ABOUT",
    companyHeadText: "Double Force trademark is a property of the English company DFT. The main activity of the company is the development of technologies and products for the automotive market. DFT places orders for the production of its spare parts in more than 60 manufacturing plants located in the UK, the EU, Japan, Korea and PRC, which supply their products to the OEM and the secondary market.",
    companyMiddleLeft: "All of DFT’s own plants and partner factories are accredited according to the quality management standards ISO 9001:2015 or ISO 9004:2009, and also to the industry standard ISO/TS 16949:2009 for OEM products.",
    companyMiddleRight1: "When developing and manufacturing spare parts under the Double Force brand for its customers, DFT follows these key principles:",
    companyMiddleRight2: "Conformity of the products to international quality standards",
    companyMiddleRight3: "The final cost of the product affordable to the consumer",
    companyMiddleRight4: "Development of a wide range of all popular car models",
    companyMiddleBlue: "The main product groups produced under the Double Force brand:",
    companyAlliasTop1: "BRAKE SYSTEM COMPONENTS",
    companyAlliasTop2: "FILTERS",
    companyAlliasTop3: "TRANSMISSION PARTS",
    companyAlliasBottom1: "SUSPENSION PARTS",
    companyAlliasBottom2: "ELECTRTRICAL EQUIPMENT",
    companyAlliasBottom3: "DETAILS OF THE COOLING SYSTEM",
    companyMiddleBottTitle: "HISTORY",
    companyBottomLeft1: "The history of Double Force Technology begins in the 60s of the XIX century in Berkhamsted, Hertfordshire. In those years Berkhamsted was a connecting link in England’s growing network of roads, canals, and railways.",
    companyBottomLeft2: "Originally founded under name Raven’s Lane Manufactory, the company specialized in equipment for the construction of barges required to transport goods via canals of the British Isles. The rapid development of the railway network and then of motor transport changed the company’s specialization. In 1921, the Raven`s Lane Manufactory company received the name DFT, which stood for Department For Transport.",
    companyBottomRight1: " Following the growth of technology, a research and production division of Double Force Technology was created, which on a yearly basis manufactures top products for the car market: braking and cooling systems, transmission and suspension components, filters, and electrical equipment. Headquarter of DFT department responsible for car parts development is located in Caterham, Surrey, UK.",
    companyBottomLeft3: "Double Force Technology places orders for the production of its spare parts in more than 60 manufacturing plants located in the UK, the EU, Japan, Korea and PRC. All of DFT’s own plants and partner factories are accredited according to the quality management standards ISO 9001:2015 or ISO 9004:2009, and also to the industry standard ISO/TS 16949:2009 for OEM products. The facilities use a single DFT engineering database.",
    companyBottomRight2: "Since June 2017, Double Force products have become available to Russian car owners. The result of a long negotiation process was the conclusion of a distribution contract with one of the leaders in the Russian car parts market – Unicom.",
    companyBottomRight3: "The first product groups supplied to the Russian market were brake pads and filters by Double Force.",
    /** MOUNTS PAGE */
    mountsHeaderTitle: "Shock <br>mounts",
    mountsTopTitleLeft: "Production",
    mountsTopTitleRight: "Vulcanization",
    mountsMiddleTitle: "Adhesive",
    mountsBottomTitleLeft: "Painting",
    mountsBottomTitle: "Bearings",
    mountsTopTextLeft: "The shock mounts are produced using a more advanced technology compared to the original parts, but they remain in full compliance with the design.",
    mountsTopTextRight: "The elastomeric mixture undergoes a vulcanization process with the addition of stabilizers and plasticizers.",
    mountsMiddleText: "To reliably connect rubber to metal parts of products, rubber-based adhesive with the addition of a vulcanization agent is used; to enhance the adhesive properties, a primer is applied to the surface of the metal parts; bonding occurs at the molecular level, during which mutual penetration of the adhesive composition and the rubber mixture occurs.",
    mountsBottomTextLeft: "Products are painted with polymer paints and varnishes using the electrodeposition method to obtain an anti-corrosion coating that is resistant to chipping. Additional anti-corrosion treatment increases the service life of the mounts.",
    mountsBottomText: "The bearings are made of high-carbon bearing steel, the housing is made of thermoplastic impact-resistant copolymer, the inner surface is made of polyamide, which increases wear resistance, and lithium EP grease, which reduces the coefficient of friction.",
    /** ABSORBERS PAGE */
    absorbersHead: "Struts and Shock Absorbers",
    absorbersTitle1: "Installation features",
    absorbersTitle2: "Production",
    absorbersTitle3: "Rod",
    absorbersTitle4: "Pistons",
    absorbersTitle5: "Oil",
    absorbersTitle6: "Laboratory testing  <br> of shock absorbers",
    absorbersText1: "All Double Force shock absorbers are of a two-tube design; therefore, they must be primed prior to installation. There should be no gas in the inner cylinder (tube). After priming the shock absorbers, during all further manipulations with them up to installation on the car the rod must be facing up.",
    absorbersText2: "The use of electric arc welding technology on robotic welding conductors does not cause damage to the inner surface of the strut body (tube), while allowing welding the cup to the strut body with maximum reliability and strength. Welding the shock absorber body with a rubber-metal hinge can withstand a breaking force of at least 20 kN.",
    absorbersText3: "The shock rod is chrome plated and polished for corrosion resistance and superior sealing. It undergoes high-temperature quenching, high-frequency processing and grinding, which prevents the destruction of the oil seal. The oil seal is produced separately at the original NOK parts factory.",
    absorbersText4: "They are made from wear-resistant polymers with anti-friction coating, which prevents wear and jamming in extreme conditions.",
    absorbersText5: "The presence of a membrane inside the shock-absorbing device allows the oil and air to be separated. Synthetic oil with a high viscosity index and excellent performance at low temperatures down to −40 °C allows preserving stable shock absorber parameters.",
    absorbersText6: "A laboratory accredited according to EAEU standards for testing car components for compliance with the requirements specified in TR CU 018/2011 and Russian GOST 34339-20117 standards. All bench equipment presented in the laboratory is certified by the relevant supervisory authorities for compliance with metrological standards.",
    absorbersText7: "The shock absorber laboratory testing process includes several operations:",
    absorbersText8: "Leak test", 
    absorbersText9: "Checking for load bearing characteristics",
    absorbersText10: "Checking for noise and other unwanted sounds",
    absorbersText11: "Temperature tests",
    absorbersText12: "Thanks to developments in fluid dynamics and damping, shock absorbers allow reducing vibrations and shaking on the road better than analogues of the same price.",
    absorbersTitle13: "Wide range of SKU for different types of vehicles",
    absorbersTitle14: "Thorough testing before release to the market",
    absorbersTitle15: "High quality and reliability",
    /** AIR PAGE */
    airHead: "Air <br> filters",
    airTitle1: "Medium and materials",
    airTitle2: "Composition",
    airTitle3: "Quality",
    airText1: " Filtering medium with flammability class F1 or S1 and synthetic pre-cleaners do not support fire – the flame becomes extinct by itself.  <br> Filtering materials based on cellulose or synthetic microporous crossed fibres are treated with synthetic resin, which forms a fixed matrix of pores.",
    airText2: "The filters are equipped with an elastic sealing band for a tight fit, stiffening ribs, and shutter fixation. <br>Gaskets – polyurethane mixture ensures a seal between clean and dirty filter sides. <br>The corrugated pipes are fixed using a perforated coating preventing adhesion of adjacent folds.",
    airText3: "Double Force conducted laboratory tests  of DFA2964 air filter<br> to evaluate consumer properties in comparison with competitors. <br>This filter showed excellent results.",
    airListText1: "High-molecular elastic composition with a tight connection at a temperature of 50 °C +150 °C.",
    airListText2: "The filter element is made on the basis of a complex combination of cellulose and synthetic fibres.",
    airListText3: "Cold rolled steel and galvanized cover undergo thermoforming or deep drawing.",
    airListText4: "Anti-corrosion polymer-powder coating.", 
    airListText5: "Precision valves.",
    airListText6: "Steel central tube with additional internal rigidity.",
    airListText7: "Increased filter surface area.",
    airListText8: "The O-ring is made from oil and petrol resistant NBR rubber.",
    airQualityText1: "Comply with ISO 5011 and GOST 8002 standards",
    airQualityText2: "Filters are tested at a pressure of 13.5 atm.",
    airQualityText3: "Certified according to ISO/TS 16949 international standard.",
    airBenefitsText1: "Extended life",
    airBenefitsText2: "Special dipping prevents water absorption",
    airBenefitsText3: "Optimal fold geometry",
    airBenefitsText4: "Polyurethane provides maximum adhesion (grip)",
    airBenefitsText5: "Increased dust capacity",
    airBenefitsText6: "Filters are resistant to deformation",
    /** BRAKE PAGE */
    brakeHeaderTitle: "BRAKE <br> PADS",
    brakeTitle1: "Complete absence of noise and vibration",
    brakeTitle2: "Low dust generation",
    brakeTitle3: "Safety",
    brakeTitle4: "Environmentally friendly",
    brakeTitle5: "Long service life",
    brakeText1: "A unique three-level noise reduction technology, including computer modelling of the pad geometry, the application of slots and bevels at the product ends, provides unsurpassed comfort when braking at any speed.",
    brakeText2: "Low-dust technology significantly reduces brake dust deposits on wheel discs. Using our brake pads ensures less wear on the brake disc, which is the main cause of dark-coloured dust.",
    brakeText3: "The high stopping power of Double Force brake pads guarantees a short braking distance for your vehicle. High response at hard braking ensures maximum safety when driving at high speeds. You will be confident in your brakes, regardless of whether you are making regular trips around the city or cruising along a country highway.",
    brakeText4: "The special composition of the friction material is characterized by increased stability of properties and provides a high coefficient of friction at any temperature, which makes braking predictable and safe. Advanced bonding technology guarantees reliable fixation of the friction material on the metal base of the pad.",
    brakeText5: "Our brake pads last longer than industry standards for passenger vehicles. Non-aggressive to the brake disc.",
    brakeText6: "Our brake pads are certified for compliance with strict European standards, American and international standards.",
    brakeText7: "Double Force brake pads do not contain asbestos, heavy metals or other materials harmful to humans or the environment.",
    brakeText8: "The friction material has a longer life and increases the overall service interval.",
    brakeText9: "In order to compare the consumer properties of Double Force brake pads with competitors on the Russian market, laboratory studies were carried out. Results on pad wear rate, disc wear, and the connection strength of the friction facings are below.",
    brakeText10: "*it was not possible to quantify disk wear, since the difference in measurements before and after testing was within the measurement accuracy of the measuring instrument",
    brakeText11: "FADE is a test that measures the efficiency of pads when heated. The results of measuring the friction coefficient depending on temperature and other conditions will be summarized a little later.",
    brakeBenefitsText1: "Quality of original products",
    brakeBenefitsText2: "All brake pads are tested on test benches, which guarantees their reliability and durability",
    brakeBenefitsText3: "Thanks to the high automation of the manufacturing process, the company provides high accuracy of sizes and shapes of brake pads",
    brakeBenefitsText4: "Compliance with ECE R-90 standard, which is also responsible for environmental friendliness, as well as increased wear resistance, and improved performance characteristics",
    /** ACPP PAGE */
    acppHeaderTitle: "Filters for <br/> automatic transmission",
    acppText1: "The Double Force range of transmission filters covers more than 90% of Japanese cars equipped with automatic and continuously variable transmissions.",
    acppText2: "Double Force transmission filters are equipped with the necessary gaskets and seals.",
    acppText3: "Double Force transmission filters are produced at modern facilities in accordance with international standards.",
    acppText4: "The quality of the filters is controlled at every stage and fully complies with the requirements for original products.",
    acppText5: "Products are supplied to the markets of Europe, North America, and Asia.",
    acppText6: "Double Force transmission filters provide unsurpassed filtration quality and reliably protect internal transmission elements from contaminants and wear products.",
    acppText7: "Thanks to the use of high-quality materials, Double Force transmission filters have a long service life and maintain excellent filtration characteristics for a long time. The service life of Double Force transmission filters exceeds the service interval of transmission fluid change recommended by automakers.",
    acppText8: "All products undergo thorough quality control at all stages of production.",
    acppText9: "Additional filter tests in laboratories of the Russian Federation confirm the high quality and guarantee the safety of Double Force transmission filters.", 
    acppTitle1: "World-class manufacturing",
    acppTitle2: "High consumer properties",
    acppTitle3: "Long life",
    acppTitle4: "Total quality control",
    acppBenefitsText1: "Minimum manufacturing tolerances provide  <br> <span style='font-weight: 700'>low friction </span>and, due to this, <span style='font-weight: 700'>high efficiency</span>",
    acppBenefitsText2: "<span style='font-weight: 700'> High performance </span>and maximum service life.",
    acppBenefitsText3: "System of <span style='font-weight: 700'>100% quality control </span> of the technological process.",
    /** SHRUS PAGE */
    shrusHeaderTitle: "CV Joints",
    shrusTitle1: "Production",
    shrusTitle3: "Laboratory tests",
    shrusText1: "CV joint components are made of alloy steel thermally treated with high-frequency currents and with the use of metal hardening technologies.  <br>CV joints operate at ambient temperatures −40...+50 °C.",
    shrusText2: "CV joint assemblies include branded BP molybdenum grease, a retaining ring, a protective cover with two metal clamps, and a hub fastening nut. <br> The lubricant is made with molybdenum disulphide, which makes the lubricant high-temperature, water-resistant and consistent. Moreover, anti-friction and anti-corrosion additives are used.<br> Dust seals are produced of a rubber mixture made from natural rubber that is resistant to weather conditions, temperature changes, and the ingress of chemicals and reagents.",
    shrusText3: "Output check using 11 parameters in automated test laboratory: build quality, geometry, torque margin, etc.<br>Meet the requirements of standards GB/T308-2002, DIN5401/, ISO/TS 16949 GOST R ISO/TU 16949-2009.",
    shrusBenefitsText1: "The outboard CV joints use the classic cage design with ball bearings.",
    shrusBenefitsText2: "Inboard CV joints are of up-to-date tripod design.",
    shrusBenefitsText3: "Hinge components are made of alloyed structural steel and are subject to heat treatment with high frequency currents",
    shrusBenefitsText4: "All CV joints are chemically treated to increase wear resistance and anti-corrosion protection. Lubrication applied at the same time also helps reduce noise and improve the quality of CV joint operation.",
    /** CABIN PAGE */
    cabinHeaderTitle: "Cabin  <br/> filters",
    cabinTitle1: "Reliable barrier",
    cabinTitle2: "Suitable for most cars",
    cabinTitle3: "Filter element from the world leader",
    cabinTitle4: "Comfortable breathing and health protection",
    cabinTitle5: "High performance",
    cabinTitle6: "Extended period of effective use",
    cabinText1: "Clean air in the car is the key to a good mood and comfort during the trip. An old cabin filter creates many problems. Dust, fumes, unpleasant odors, and allergens irritate eyes and respiratory organs and quickly tire the driver and passengers. Contamination is accumulated inside the car, eventually exceeding street levels by five times. Foggy windows obstruct the view. To avoid these problems, change your cabin filter to DOUBLE FORCE filter. The products of the British company DFT are distinguished by ideal compatibility, attractive price and guaranteed quality.",
    cabinText2: "Efficiency of cleaning from mechanical particles –more than 96%",
    cabinText3: "DOUBLE FORCE range of cabin filters covers most cars of European, Japanese and Korean brands.",
    cabinText4: "DOUBLE FORCE cabin filters use components from the world’s leading suppliers. This guarantees full preservation of the technical characteristics of DOUBLE FORCE filters throughout their entire service life.",
    cabinText5: "Standard DOUBLE FORCE cabin filters effectively fight contaminants up to 8 microns in size. A composite filter element based on nylon and cotton from 3M company is responsible for this. Standard DOUBLE FORCE cabin filters will protect you from dust, soot, pollen, and mould spores.",
    cabinText6: "Low resistance to air flow of DOUBLE FORCE cabin filters allow you to filter a larger volume of air per unit of time.",
    cabinText7: "The use of non-woven synthetic material in DOUBLE FORCE cabin filters allows us to gently take care of the health of the driver and passengers for a long time. Change cabin filters to DOUBLE FORCE products once a year or every 15,000 km.",
    cabinListText1: "Antibacterial dipping",
    cabinListText2: "Electrostatic charge",
    cabinListText3: "Synthetic non-woven material based on nylon and cotton",
    cabinListText4: "Double structure with mechanical filtering",
    cabinListText5: "Precise fit eliminates air leaks-in",
    /** HUBS PAGE */
    hubsHeaderTitle: "Hubs <br/> and hub assemblies",
    hubsTitle1: "Rigidity and strength",
    hubsTitle3: "Laboratory tests",
    hubsText1: "Cages made of polyamide are additionally reinforced with glass fibre, which increases rigidity and strength, guarantees uniform distribution of rolling elements and eliminates jamming.",
    hubsText2: "Automated selection of rolling elements guarantees uniform load distribution, reduced vibration and friction losses, and increases the stability of the vibroacoustic characteristics that arise during bearing operation.",
    hubsText3: "Surface induction hardening of the raceways increases the hardness, wear resistance and shock resistance of rolling elements.",
    hubsText4: "Lithium EP grease that maintains its properties in the temperature range −40...+180 °C.",
    hubsText5: "The oil seal design protects the hub assembly from external contaminants, keeps lubricant inside the bearing and is resistant to wear in harsh environments.",
    hubsText6: "The bolts are treated with ultra-resistant zinc aluminium coating, which reliably protects against corrosion throughout the service life.",
    hubsText7: "Built-in sensors and magnetic encoders are manufactured according to strict international standards and guarantee accurate ABS operation for the safety of the driver and passengers when the vehicle is moving.",
    hubsText8: "Hubs manufactured c/w bearings are tested on a test bench, where the rotation of a wheel around the axis is simulated. The resulting play is measured and a conclusion is made as to whether the wheel hub assembly is suitable for use. Each wheel hub is tested using micrometric equipment, checking that the hub dimensions correspond to the required dimensions. As a result, parts with incorrect geometry are rejected, and vibrations in the brake mechanism are eliminated.",
    hubsBenefitsText1: "The product range is represented by a  <span style='font-weight: 700'>large number </span> of models.",
    hubsBenefitsText2: "Hubs are produced using <span style='font-weight: 700'>advanced technologies.</span>", 
    hubsBenefitsText3: "The emphasis is on <span style='font-weight: 700'>easy installation </span> of the product, therefore, installing the hub <span style='font-weight: 700'>will not take much time</span>",
    hubsBenefitsText4: "<span style='font-weight: 700'> Adaptation</span> to Russian conditions.",
    /** FUEL PAGE */
    fuelHeaderTitle: "Modular <br> fuel <br>filters",
    fuelText1: "Modular fuel filters are an innovative solution that ensures the purity and quality of the fuel entering the car engine. These filters play an important role in preventing the entry of harmful impurities, dirt, and microparticles into the vehicle feed system.",
    fuelText2: "The principle of operation of fuel filters is based on passing fuel through a special filtering material. This material has tiny pores that trap impurities and particles, preventing them from entering the engine feed system. This ensures the purity and quality of the fuel.",
    fuelTitle1: "High filtration efficiency",
    fuelTitle2: "Long service life",
    fuelTitle3: "Protection against breakdowns",
    fuelText3: "They have a high degree of filtration and trap even the smallest particles, which helps improve fuel quality. This allows to reduce engine wear and increase its life time.",
    fuelText4: "Thanks to the use of durable and high-quality material, fuel filters ensure long-term operation without loss of their properties and efficiency.",
    fuelText5: "Prevent dirt and small particles from entering engine feed system, which helps prevent breakdowns and costly repairs.",
    /** COILS PAGE */
    coilsHeaderTitle: "Ignition  <br/>coils",
    coilsTitle1: "Production features",
    coilsTitle3: "Quality",
    coilsText1: "DOUBLE FORCE ignition coils – OEM quality products. Products include a range for European, American, Japanese and Korean cars. The number of items in the product range is more than 150 items suitable for different types of cars.",
    coilsText2: "Fewer production operations, lower metal consumption and labour intensity of production, which is reflected in the final price of the product.",
    coilsText3: "The insulating material of the coils does not allow the appearance of bubbles in the material.",
    coilsText4: "Enamelled wire is resistant to temperatures up to 180 °C, while non-enamelled wire is resistant to temperatures up to 140 °C.",
    coilsText5: "The secondary winding spool can withstand a voltage of 33 KV/MM versus conventional plastic of 20 KV/MM.",
    coilsText6: "The electronic module is a 1.2 mm circuit board with increased mechanical strength and electrical insulating properties.",
    coilsText7: "Wires of increased thickness with improved performance.", 
    coilsText8: "Leads of primary and secondary windings, other electrical components, wires at a safe distance, which eliminates electrical interference.",
    coilsText9: "Core – a material with a smooth surface is used for increased productivity.",
    coilsText10: "Product quality is proved by quality certificates: TUV, TS 16949, ISO 9001.",
    /** OIL PAGE */
    oilsHeaderTitle: "Oil <br/>filters",
    oilsTitle1: "Production features",
    oilsText1: "DOUBLE FORCE oil filters ensure oil cleanliness in the engine preventing thereby early aging and wear of mating parts.",
    oilsText2: "These filtering systems have been designed up to OEM requirements and are above these requirements in some aspects – some components of these filters have an increased safety factor to make vehicle’s performance more reliable.",
    oilsText3: "The production of filtering systems is based on research, testing and use of advanced technologies.",    
    oilsText4: "The product’s highest degree of purification – up to 99% – has been confirmed by tests performed in accordance with ISO 4548-12 standard.",
    oilsText5: "A precisely tuned bypass valve ensures a uniform oil flow through the system even in case of high viscosity of oil and expiry of the filter’s service life.",
    oilsText6: "The product is compliant with the standards of three standardization and quality organizations with the most stringent requirements – SAE (USA), JIS (Japan) and DIN (Germany) – and certified according to ISO / TS 16949, ISO 17025, ISO 14001.",
    oilsText7: "The high reliability and efficiency of DOUBLE FORCE oil filters is ensured by the robust construction of their body, proper design of the safety bypass valve and anti-drainback valve, and use of a composite-based new filtering material resistant to packetizing.",
    oilsText8: "Due to special formation and deep corrugation of the filtering material, it has a large filtering area and prevents access of non-purified oil to engine parts. The high strength of its body elements, filtering element and connections (flaring and seal gasket) protects the filter against breakage in case of abrupt pressure surges in the oil system or mechanical impacts in case of wrong installation.", 
    oilsText9: "Factory testing performed during 9 hours at temperature of −40 °C and pressure of 9 bars confirms that these oil filters have an increased safety factor higher than required by OEM.",
    /** SPRINGS PAGE */
    springsHeaderTitle: "SUSPENSION <br>SPRINGS",
    springsTitle1: "Cold automatic winding of springs",
    springsTitle2: "Electrostatic painting method",
    springsTitle3: "Alloying with chromium and silicon",
    springsTitle4: "Shot blasting of springs",
    springsTitle5: "Pre-stressing",
    springsTitle6: "Multi-stage low temperature heat treatment",
    springsTitle7: "Zinc phosphating of springs",
    springsTitle8: "Powder coating of springs",
    springsText1: "A spring element is winded in a cold state using special camless spring forming machines.",
    springsText2: "Allows one to deliver material to hard-to-reach places, ensure uniform layer coverage, and avoid defects.",
    springsText3: "Chromium increases corrosion resistance, electrical resistance, and strength. Silicon increases heat resistance and hardness.",
    springsText4: "In a free state, it increases the endurance limit of springs by 80%, and in a statically stressed state – by 92%.",
    springsText5: "Threefold full compression of a spring increases the strengthening effect.",
    springsText6: "In a free state, it increases the endurance limit of springs by 80%, and in a statically stressed state – by 92%.", 
    springsText7: "Increased tensile strength, elastic limits and torsional yield. Phosphating decreases the number of bends.", 
    springsText8: "A method for producing polymer coatings that have protective properties, preventing mechanical damage.",
    springsBenefitsText1: " <span style='font-weight: 700;'>Minimum percentage of defects, </span> since each spare part undergoes strict quality control.",
    springsBenefitsText2: " The springs have a complex <strong style='font-weight: 700;'>multi-layer protective surface application, </strong> that has a polymer primer. This special coating completely  <strong style='font-weight: 700;'>protects the spring surface </strong> against corrosion and damage in places where stones and gravel hit.",
    springsBenefitsText3: "<strong style='font-weight: 700;'>Side loading </strong> spring technology <strong style='font-weight: 700;'>extends the life </strong> of suspension elements.",
    /** menu */
    menu: 'Menu',
    menuMain: 'Home',
    menuHome: "Double Force",
    menuCatalog: "Catalogue",
    menuInfo: "Information",
    menuWhereToBuy: "Where to buy",
    menuAssurance: "Assurance",
    menuPartners: "For partners",
    menuAbout: "Company",
    menuSelection: "Find a part",
    /** modal feedback marketplace */
    modalFeedback: "Feedback",
    marketplaceTitle: "Choose a marketplace",
    marketplaceLinkRetail: "Retail stores on the map",
    /** catalog item */
    catalogWiper: "Wiper blades",
    catalogCoils: "Ignition coils",
    catalogAbsorbers: "Shock absorbers",
    catalogMounts: "Shock mounts",
    catalogAir: "Air filters",
    catalogFuel: "Modular fuel filters",
    catalogCabin: "Cabin filters",
    catalogHubs: "Hubs and hub assemblies",
    catalogBrake: "Brake pads",
    catalogAcpp: "Filters for automatic transmission",
    catalogShruses: "CV Joints",
    catalogSprings: "Suspension springs",
    catalogOil: "Oil filters",
    /** main-slogan */
    homeSlogan1: "Quality",
    homeSlogan2: "Acceptable",
    homeSlogan3: "price",
    homeSlogan4: "Convenience",
    homeSlogan5: "Complete set",
    /** store */
    storeRefText: 'Purchase products online or in stores in your city',
    storeRefLink: 'On the map',


    /**catalog/cabinFilters */
    cabinHead: "Cabin <br> filters",
    cabinHeadLg: "Cabin filters",
    cabinText1: "Clean air in the car interior is the key to a good mood and comfort during the trip. An old cabin filter creates many problems. Dust, fumes, unpleasant odors, and allergens irritate the eyes and respiratory organs and quickly tire the driver and passengers. Pollution accumulates inside the car, eventually exceeding street levels by five times. Foggy windows obstruct your view. To avoid these problems, change your cabin filter to DOUBLE FORCE. The products of the British company DFT are distinguished by ideal compatibility, attractive prices and guaranteed quality.",
    cabinText2: "Reliable barrier",
    cabinText3: "Cleaning efficiency from mechanical particles - more than 96%",
    cabinText4: "Suitable for most cars",
    cabinText5: "The range of DOUBLE FORCE cabin filters covers most cars of European, Japanese and Korean brands.",
    cabinText6: "Filter element from the world leader",
    cabinText7: "DOUBLE FORCE cabin filters use components from the world's leading suppliers. This guarantees full preservation of the technical characteristics of DOUBLE FORCE filters throughout their entire service life.",
    cabinText8: "Comfortable breathing and health protection",
    cabinText9: "Standard DOUBLE FORCE cabin filters effectively combat contaminants up to 8 microns in size. A composite filter element based on nylon and cotton from 3M is responsible for this. Standard DOUBLE FORCE cabin filters will protect you from dust, soot, pollen, and mold spores.",
    cabinText10: "High performance",
    cabinText11: "The low resistance to air flow of DOUBLE FORCE cabin filters allows you to filter a larger volume of air per unit of time.",
    cabinText12: "Extended period of effective use",
    cabinText13: "The use of non-woven synthetic material in DOUBLE FORCE cabin filters allows us to gently take care of the health of the driver and passengers for a long time. Change cabin filters to DOUBLE FORCE products once a year or every 15,000 km.",
    cabinText14: "Antibacterial impregnation",
    cabinText15: "Electrostatic charge",
    cabinText16: "Synthetic non-woven material based on nylon and cotton",
    cabinText17: "Double structure with mechanical filtration method",
    cabinText18: "Precise fit eliminates air leaks",
    cabinTextBuy: "Buy on marketplaces",
    
    /** catalog/suspension */
    suspensionHead: "SPRINGS<br>SUSPENSIONS",
    suspensionText1: "Cold coiling of springs automatically",
    suspensionText2: "The spring element is twisted in a cold state using special units, camless multiforming machines.",
    suspensionText3: "Electrostatic dyeing method",
    suspensionText4: "Allows you to deliver material to hard-to-reach places, ensure uniform layer coverage, and avoid defects.",
    suspensionText5: "Alloying with Chromium and Silicon",
    suspensionText6: "Chromium increases corrosion resistance, electrical resistance and strength. Silicon increases heat resistance and hardness.",
    suspensionText7: "Shot blasting of springs",
    suspensionText8: "In a free state, it increases the endurance limit of springs by 80%, and in a statically stressed state - by 92%.",
    suspensionText9: "Draft",
    suspensionText10: "Threefold full compression of the spring - which increases the strengthening effect.",
    suspensionText11: "Multi-stage low temperature heat treatment",
    suspensionText12: "In a free state, it increases the endurance limit of springs by 80%, and in a statically stressed state - by 92%.",
    suspensionText13: "Zinc phosphating of springs",
    suspensionText14: "Increased tensile strength, elastic limits and torsional yield. The number of kinks as a result of its phosphating decreases.",
    suspensionText15: "Powder coating of springs",
    suspensionText16: "A method for producing polymer coatings that have protective properties, protecting against mechanical damage.",
    suspensionText17: "Advantages",
    suspensionUl1: "Minimum defect rate",
    suspensionUl2: ", since each spare part undergoes strict quality control",
    suspensionUl3: "Springs have a complex",
    suspensionUl4: "multilayer protective surface application",
    suspensionUl5: ", which has a polymer primer. This special coating completely ensures",
    suspensionUl6: "high protection of the spring surface",
    suspensionUl7: "from corrosion and the appearance of destruction in places where stones and gravel hit",
    suspensionUl8: "Side Load Technology",
    suspensionUl9: "springs",
    suspensionUl10: "extends service life",
    suspensionUl11: "suspension elements",
    suspensionButton: "Buy on marketplaces",

    shrusesHead: "Cv joint",
    shrusesText1: "production",
    shrusesText2: "CV joint parts are made of alloy steel with thermal treatment with high-frequency currents and the use of metal hardening technologies.<br>CV joints operate at ambient temperatures -40 +50 °C",
    shrusesText3: "EQUIPMENT",
    shrusesText4: "The CV joints are equipped with branded BP molybdenum grease, a retaining ring, a protective cover with two metal clamps and a hub fastening nut.<br>The lubricant is made with molybdenum disulfide, which makes the lubricant high-temperature, water-resistant and consistent. As a plus, anti-friction and anti-corrosion additives are added.<br>Anthers are produced on the basis of a rubber mixture made from natural rubber that is resistant to weather conditions, temperature changes, and the ingress of chemicals and reagents.",
    shrusesText5: "Laboratory tests",
    shrusesText6: "Output testing for 11 parameters in an automated test laboratory: build quality, geometry, torque reserve, etc.<br>Meets the requirements of standards GB/T308-2002, DIN5401/, ISO/TS 16949 GOST R ISO/TU 16949-2009",
    shrusesText7: "Advantages",
    shrusesText8: "The outer CV joints use a classic cage design with ball bearings",
    shrusesText9: "Inner CV joints have a modern tripoid design",
    shrusesText10: "Hinge parts are made of alloy structural steel and heat treated with high frequency currents",
    shrusesText11: "All CV joints undergo chemical treatment to increase wear resistance and corrosion protection. The lubricant applied in this process also helps reduce noise and improve the quality of the CV joint.",
    shrusesText12: "Buy on marketplaces",

    /** catalog/wiper */
    wiperHead: "WIPER<br>BLADES",
    wiperText1: "WIPER BLADES",
    wiperText2: "Certified according to international quality management ISO/TS 16949.",
    wiperText3: "Multi-stage painting technology and the use of hinged joints with independent support points eliminate accidental breakage or the possibility of corrosion.",
    wiperText4: "Complete sets of all common types of fastenings in each brush.",
    wiperText5: "The price is one of the lowest on the market in the economy segment.",
    wiperText6: "Frame (Standard)",
    wiperText7: "All-metal, galvanized body.",
    wiperText8: "Uniform distribution of contact points.",
    wiperText9: "Wear-resistant rubber brush blades.",
    wiperText10: "Low-profile, thermoplastic inserts at the rocker interfaces prevent ice build-up for excellent performance.",
    wiperText11: "Mounting method No. 1",
    wiperText12: "Mounting method No. 2",
    wiperText13: "frameless",
    wiperText14: "No exposed metal elements.",
    wiperText15: "Flat profile.",
    wiperText16: "High-tech steel spring strips provide constant high pressure along the entire length of the wipers.",
    wiperText17: "Aerodynamic, streamlined, one-piece design that reduces air pressure and noise and provides maximum clamping force at high speeds.",
    wiperText18: "Mounting methods:",
    wiperText19: "HYBRINE BRUSHES",
    wiperText20: "The integrated aerodynamic design with rubber sheet, NANO-Graphit (liquid graphite) coating and precision cut edge retains its properties at temperatures from -30C to +50C.",
    wiperText21: "Fully enclosed steel body and low profile design",
    wiperText22: "Stainless steel spring for optimal pressure on glass",
    wiperText23: "Mounting methods:",
    wiperText24: "WINTER BRUSHES",
    wiperText25: "Waterproof, sealed case",
    wiperText26: "Enlarged wiper blade profile",
    wiperText27: "The shape and material of rubber brushes are designed taking into account cold winters and do not freeze",
    wiperText28: "Aerodynamic folds of rubber work to compress in the middle of the wiper stroke, which maintains elasticity at temperatures down to -40 ºС.",
    wiperText29: "Low noise at any level of wear",
    wiperText30: "Steel with anti-corrosion coating",
    wiperText31: "MOUNTINGS INCLUDED",
    wiperText32: "Buy on marketplaces",
    wiperText33: "SIZE RULE",
    wiperText34: "Winter brushes",
    wiperText35: "Vendor code",
    wiperText36: "Frame brushes",
    wiperText37: "Frameless brushes",
    wiperText38: "Hybrid brushes",

    header1: "Company",
    header2: "For partners",
    header3: "Catalog",
    header4: "Guarantee",
    header5: "Where can I buy",

    listHome1: "The wide range of Double Force is represented by analogues of OEM spare parts for cars of old years of manufacture to the latest models.",
    listHome2: "All groups",
    
};
