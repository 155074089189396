const Home = () => import(/* webpackChunkName: "home" */ "./pages/Home.vue");
const StoreMap = () => import(/* webpackChunkName: "storemap" */ "./pages/StoreMap.vue");
const Catalog = () => import(/* webpackChunkName: "catalog" */ "./pages/ProductPage.vue");
const Mounts = () => import(/* webpackChunkName: "mounts" */ "./pages/Products/MountsPage.vue");
const Fuel = () => import(/* webpackChunkName: "fuel" */ "./pages/Products/FuelPage.vue");
const Hubs = () => import(/* webpackChunkName: "hubs" */ "./pages/Products/HubsPage.vue");
const Acpp = () => import(/* webpackChunkName: "acpp" */ "./pages/Products/AcppPage.vue");
const Coils = () => import(/* webpackChunkName: "coils" */ "./pages/Products/CoilsPage.vue");
const OilFilters = () => import(/* webpackChunkName: "coils" */ "./pages/Products/OilFilters.vue");
const Assurance = () => import(/* webpackChunkName: "assurance" */ "./pages/Assurance.vue");
const Partners = () => import(/* webpackChunkName: "partners" */ "./pages/Partners.vue");
const Absorbers = () => import(/* webpuckChunkName: "absorbers" */ "./pages/Products/Absorbers.vue");
const AirFilters = () => import(/* webpuckChunkName "air-filters" */ "./pages/Products/AirFilters.vue");
const CabinFilters = () => import(/* webpuckChunkName "cabin-filters" */ "./pages/Products/CabinFilters.vue");
const Brake = () => import(/* webpuckChunkName "brake" */ "./pages/Products/Brake.vue");
const Shruses = () => import(/* webpuckChunkName "shruses" */ "./pages/Products/Shruses.vue");
const Springs = () => import(/* webpuckChunkName "springs" */ "./pages/Products/Springs.vue");
const Company = () => import(/* webpuckChunkName "company" */ "./pages/Company.vue");
const Wiper = () => import(/* webpuckChunkName */ "./pages/Products/Wiper.vue");
const SelectionPage = () => import(/* webpackChunkName: "selectionpage" */ "./pages/SelectionPage.vue");
const SelectionProduct = () => import(/* webpackChunkName: "selectionproduct" */ "./pages/SelectionProduct.vue");


const routes = [
    {
        path: "/:locale",
        children: [
            {
                path: "",
                name: "Home",
                component: Home,
            },
            {
                path: "selection/:selection(parts|wipers|all)",
                name: "SelectionPage",
                component: SelectionPage,
                meta: { titleI18n: "Продукция по подбору Double Force" },
            },
            {
                path: "selection/:selection(parts|wipers)/:id",
                name: "SelectionProduct",
                component: SelectionProduct,
                meta: { titleI18n: "Продукция по подбору Double Force" },
            },
            {
                path: "catalog",
                name: "Catalog",
                component: Catalog,
                meta: { titleI18n: "Каталог продукции Double Force" },
            },
            {
                path: "catalog/mounts",
                name: "Mounts",
                component: Mounts,
                meta: { titleI18n: "Опоры Амортизаторов Double Force" },
            },
            {
                path: "catalog/fuel",
                name: "Fuel",
                component: Fuel,
                meta: { titleI18n: "Модульные Топливные Фильтры Double Force" },
            },
            {
                path: "catalog/hubs",
                name: "Hubs",
                component: Hubs,
                meta: { titleI18n: "Ступицы и Ступичные Узлы Double Force" },
            },
            {
                path: "catalog/acpp",
                name: "Acpp",
                component: Acpp,
                meta: { titleI18n: "Фильтры для АКПП Double Force" },
            },
            {
                path: "catalog/coils",
                name: "Coils",
                component: Coils,
                meta: { titleI18n: "Катушки Зажигания Double Force" },
            },
            {
                path: "catalog/oil",
                name: "OilFilters",
                component: OilFilters,
                meta: { titleI18n: "Масляные Фильтры Double Force" },
            },
            {
                path: "assurance",
                name: "Assurance",
                component: Assurance,
                meta: { titleI18n: "Гарантия Double Force" },
            },
            {
                path: "partners",
                name: "Partners",
                component: Partners,
                meta: { titleI18n: "Партнерство в Double Force" },
            },
            {
                path: "catalog/absorbers",
                name: "Absorbers",
                component: Absorbers,
                meta: { titleI18n: "Стойки и Амортизаторы Double Force" },
            },
            {
                path: "catalog/air",
                name: "AirFilters",
                component: AirFilters,
                meta: { titleI18n: "Воздушные фильтры Double Force" }
            },
            {
                path: "catalog/cabin",
                name: "CabinFilters",
                component: CabinFilters,
                meta: { titleI18n: "Салонные Фильтры Double Force" }
            },
            {
                path: "catalog/brake",
                name: "Brake",
                component: Brake,
                meta: { titleI18n: "Тормозные Колодки Double Force" }
            },
            {
                path: "catalog/shruses",
                name: "Shruses",
                component: Shruses,
                meta: { titleI18n: "Шрусы Double Force" }
            },
            {
                path: "catalog/springs",
                name: "Springs",
                component: Springs,
                meta: { titleI18n: "Пружины Подвески Double Force" }
            },
            {
                path: "catalog/wiper",
                name: "Wiper",
                component: Wiper,
                meta: { titleI18n: "Щетки Стеклоочистителей Double Force" }
            },
            {
                path: "company",
                name: "Company",
                component: Company,
                meta: { titleI18n: "О компании Double Force" }
            },
            {
                path: "store",
                name: "StoreMap",
                component: StoreMap,
                meta: { titleI18n: "Где купить продукцию Double Force" },
            },
         
        ],
    },
    {
        path: "/:locale*",
        name: "notFound",
        component: Home,
    },
];

const funcBeforeResolve = async function (to, from, next, langs, i18n) {
    const hrefs = to.href.split("/");
    const { locale, t } = i18n.global;
    let toLocale = "";

    // routes disabled for non-russian locale
    const disabledRoutes = {
        en: ["Partners", "Assurance", "StoreMap", "SelectionPage", "SelectionProduct"],
    };

    for (const element of hrefs) {
        if (element.length !== 0) {
            toLocale = element;
            break;
        }
    }

    toLocale = langs.includes(toLocale) ? toLocale : null;

    if (toLocale) {
        // change locale if URL has different one
        if (locale.value !== toLocale) {
            locale.value = toLocale;
            localStorage.setItem("locale", toLocale);
        }
        
        window.scrollTo({ top: 0, behavior: "instant" });

        document.title = to.meta.titleI18n ? t(to.meta.titleI18n) : "Double Force надежные автотовары и запчасти для вашего автомобиля";
    
        if (disabledRoutes[toLocale] !== undefined && disabledRoutes[toLocale].includes(to.name)) {
            next({ path: `/${locale.value}` });
        }

        next();
        return 1;
    }

    next({path: `/${locale.value}${to.path}`, query: to.query});
    return 2;
};

export { routes, funcBeforeResolve };
