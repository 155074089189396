import axios from 'axios';

const fetchData = async (api_target) => {
  let content = []
  try {
    await axios
      .get(`${import.meta.env.VITE_APP_SERVICES_API_URL}/${api_target}`)
      .then((response) => {
        content = response.data
      });
  } catch (e) {
    console.log('We have the error', e)
  }
  return content
};

const getStaticImage = (path = null) => {
  return `${import.meta.env.VITE_APP_BACKEND_URL}${path}`;
}

export { fetchData, getStaticImage }
