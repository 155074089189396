import { watch, computed, shallowRef, ref, defineAsyncComponent } from "vue";
import { robotDetection } from "@components/bundle/YandexMap/mixins/robot.js";
import { createStore as $store } from "@/storage/index.js";
import axios from "axios";

const localCity = ref(null);
const brands = shallowRef(new Object());
const additionalQuery = ref(new Object());

const marketplaces = computed(() => {
    let marketplaces = $store.getters["static/getMarketplaces"];
    marketplaces.forEach((element) => {
        brands.value[element.id] = {
            solid: defineAsyncComponent(() => import(/* @vite-ignore*/ `@assets/img/svg/${element.solid}.svg`)),
            name: defineAsyncComponent(() => import(/* @vite-ignore*/ `@assets/img/svg/${element.name}.svg`)),
        };

        additionalQuery.value[element.id] = {};
        element.seller ? (additionalQuery.value[element.id]["seller"] = element.seller.map(s => s.id).join(",")) : null;
    });
    return marketplaces;
});

watch(localCity, (val) => {
    marketplaces.value.forEach((element) => {
        if (element.seller) {
            const localSeller = getLocalSeller(element.seller);
            if (localSeller && localSeller.id) {
                additionalQuery.value[element.id]["seller"] = localSeller.id;
            }
        }
    });
});

function buildQuery(queryObject, mp) {
    let search = import.meta.env.VITE_APP_NAME;
    let productQuery = '';
    let result = "";
    const params = [];
    const filters = mp.filters ?? "";
    const product = $store.getters["default/getProduct"] ?? search;

    const mpProduct = mp.products[product] !== undefined ? mp.products[product] : { value: product };

    if (mp.query.length > 0) {
        params.push(mp.query);
    }

    Object.keys(queryObject).forEach((key) => {
        params.push(`${key}=${queryObject[key]}`);
    });

    if (mpProduct) {
        productQuery += mpProduct['query'] ?? '';
        const search = mpProduct["property"] ?? mp["search_property"];
        search !== null && mpProduct["value"] ? params.push(`${search}=${mpProduct["value"]}`) : null;
    }

    result = "?" + params.join('&') + productQuery + filters

    if (result.length === 0) brands.value[mp.id]['no-result'] = true;
    else brands.value[mp.id]['no-result'] = false;

    return `${mp.link}${result}`;
}

function getLocalSeller(sellers) {
    if (localCity.value && sellers) {
        const localSeller = sellers.find((seller) => {
            return seller.city === localCity.value;
        });
        return localSeller;
    }

    return null;
}

async function getLocationByClientIP() {
    if (localStorage.getItem("location")) {
        localCity.value = JSON.parse(localStorage.getItem("location")).name;
        return;
    }

    let IP = null;

    if (robotDetection.value == false) {
        await axios.get(import.meta.env.VITE_APP_CLIENT_IP).then((res) => (IP = res.data));
    }

    if (null == IP) {
        return;
    }

    let response = null;
    await axios.get(`${import.meta.env.VITE_APP_GEO_URL}/${import.meta.env.VITE_APP_GEU_KEY}/json/${IP}`).then((res) => (response = res.data));

    if (response) {
        localCity.value = response.city.name_ru;
        localStorage.setItem(
            "location",
            JSON.stringify({
                lat: response.city.lat,
                lon: response.city.lon,
                name: response.city.name_ru,
            })
        );
    }
}

getLocationByClientIP();

export { buildQuery, additionalQuery, marketplaces, brands };
