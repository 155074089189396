<template>
    <div class="header" id="header">
        <div class="header-block">
            <div class="header-block-logo">
                <Logo class="header-block-logo-item" @click="link('')"/>
            </div>
            <div class="header-block-main">
                <div class="header-block-main-item" @click="link('company')">{{ $t('header1') }}</div>
                <div class="header-block-main-item" @click="link('partners')" v-if="locale === 'ru'">{{ $t('header2') }}</div>
                <div class="header-block-main-item" @click="link('catalog')">{{ $t('header3') }}</div>
                <div class="header-block-main-item" @click="link('assurance')" v-if="locale === 'ru'">{{ $t('header4') }}</div>
                <div class="header-block-main-item" @click="link('store')" v-if="locale === 'ru'">{{ $t('header5') }}</div>
            </div>
            <div class="header-block-lang">
                <LanguageBar class="header--lang-bar" />
            </div>
            <div class="header-block-burger" @click="openModal = !openModal">
                <div class="header-block-burger-item" v-if="openModal == false"></div>
                <div class="header-block-burger-item" v-if="openModal == false"></div>
                <div class="header-block-burger-item" v-if="openModal == false"></div>
                <div class="header-block-burger-exit" v-if="openModal == true"></div>
                <div class="header-block-burger-exit2" v-if="openModal == true"></div>
            </div>
        </div>
        <div class="header-modal" v-if="openModal">
            <div class="header-modal-head">
                <LanguageBar class="header--lang-bar"/>
                <div class="header-block-burger" @click="openModal = !openModal">
                    <div class="header-block-burger-exit"></div>
                    <div class="header-block-burger-exit2"></div>
                </div>
            </div>
            <div class="header-modal-block">
                <div class="header-modal-block-item" @click="link('company'), openModal = false">{{ $t('header1') }}</div>
                <div class="header-modal-block-item" @click="link('partners'), openModal = false" v-if="locale === 'ru'">{{ $t('header2') }}</div>
                <div class="header-modal-block-item" @click="link('catalog'), openModal = false">{{ $t('header3') }}</div>
                <div class="header-modal-block-item" @click="link('assurance'), openModal = false" v-if="locale === 'ru'">{{ $t('header4') }}</div>
                <div class="header-modal-block-item" @click="link('store'), openModal = false" v-if="locale === 'ru'">{{ $t('header4') }}</div>
                <img class="header-modal-block-img" src="@/assets/img/png/header/header-modal.png" alt="header-modal">
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import LanguageBar from "@components/LanguageBar.vue";
import Logo from '@/assets/img/svg/logo.svg';
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

const { locale } = useI18n();
const router = useRouter();

import '@/assets/sass/pages/header.sass';

const openModal = ref(false);

const openSelection = ref(false);

function link(link) {
    router.push(`/${link}`);
}

</script>

<style scoped>

.openSelection {
    display: flex;
    max-width: none;
}

</style>