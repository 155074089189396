<template>
    <div class="marketplace">
        <div class="marketplace-header">
            {{ $t("marketplaceTitle") }}
        </div>
        <div class="marketplace-list">
            <div v-for="item in marketplaces" :key="item.id" class="marketplace-item">
                <a 
                    :href="buildQuery(additionalQuery[item.id], item)"
                    :class="[`button`, `button-solid`, `button-marketplace`, `button-${item.name}`, { 'button-grayscale': brands[item.id]['no-result'] }]"
                    target="_blank"
                >
                    <component :is="brands[item.id]['name']" />
                </a>
            </div>
        </div>
        <div class="marketplace-footer" v-if="isRetailLink">
            <a href="/store">{{ $t("marketplaceLinkRetail") }}</a>
        </div>
    </div>
</template>

<script setup>
import { buildQuery, additionalQuery, brands, marketplaces } from "@assets/js/marketplace.js";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { locale } = useI18n();
const isRetailLink = computed(() => locale.value === "ru");
</script>

<style lang="sass" scoped>
@import '@stylebase/media.sass'
.marketplace
    flex-direction: column
    display: flex
    @include md
        justify-content: space-between
        //align-items: center
    @include sm
        min-height: 580px
    &-header
        font-weight: bold
        margin-bottom: 20px
    &-list
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        height: 100%
        align-items: flex-end
        @include md
            height: unset
            justify-content: center
    &-item
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        
    &-footer
        text-align: center
        margin-top: 20px
        a
            font-weight: bold
</style>
