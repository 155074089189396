export const ru = {
    /** components */
    buttonMore: 'Узнать подробнее',
    buttonWheretobuy: 'Магазины в твоем городе',
    buttonMarketplace: 'Купить на маркетплейсах',
    benefitsTitle: "Преимущества",
    /** PRODUCT PAGE */
    productHeaderTitle: "Продукция <br> double force",
    /** HOME PAGE */
    homeAutoParts: 'Автозапчасти',
    homeHeadtop: 'DOUBLE FORCE',
    homeHeadmiddle: "НАДЕЖНОСТЬ <br> И КАЧЕСТВО",
    homeHeadbottom: "ДЛЯ ВАШЕГО АВТОМОБИЛЯ",
    homeBluetext: "СОБСТВЕННЫЕ ПРЕДПРИЯТИЯ И ЗАВОДЫ-ПАРТНЕРЫ КОМПАНИИ DOUBLE FORCE",
    homeRighttext: "Аккредитованы по стандартам менеджмента качества ISO 9001:2015 или ISO 9004:2009, ISO/TS 16949:2009 для продуктов ОЕМ.",
    homeInfotitle:"Торговая марка Double Force <br>принадлежит Английской компании DFT",
    homeInfoleft: "Double Force - бренд автозапчастей, который предоставляет ассортимент автомобильных запчастей для японских, европейских, корейских и американских автомобилей.",
    homeInforight: "Штаб-квартира Double Force Technology -г.Катерхэм, Великобритания. В Россию с 2017 года бренд поставляет линейки продукции: деталей ходовой части, элементов электрики, тормозной и систем фильтрации, щетки стеклоочистителей.",
    homeInfoNews: "Новинка",
    homeInfoNewsTitle1: " Угольные салонные фильтры",
    homeInfoNewsTitle2: "Масляные фильтры DOUBLE FORCE",
    homeInfoNewsTitle3: "Опоры амортизаторов DOUBLE FORCE",
    homeInfoNewsText1: "В ассортименте бренда DOUBLE FORCE появилась новинка – угольные салонные фильтры.",
    homeInfoNewsText2: "Рады сообщить вам о приходе новинок бренда DOUBLE FORCE - масляных фильтров для двигателей внутреннего сгорания!", 
    homeInfoNewsText3: "Рады сообщить вам о новинках в ассортименте DOUBLE FORCE! Опоры амортизаторов DOUBLE FORCE производятся...",
    homeInfoBrandTitle: "Стать продавцом бренда<br> DOUBLE FORCE в своем городе", 
    homeInfoBrandContent: "Оптовые поставки продукции по цене изготовителя в ваш автомагазин, СТО или интернет-магазин. Условия сотрудничества оговариваются индивидуально с персональным менеджером.",
    /** FOOTER */
    footerAdressRu: "Double Force Technology, Co., Ltd., Мэйбрук Хаус, 97 Гостоун Роад, Катерхэм, Суррей CR3 6RE, Соединенное Королевство",
    /** COMPANY PAGE */
    companyHeadTitle: "О КОМПАНИИ",
    companyHeadText: "Торговая марка Double Force принадлежит Английской компании DFT. Основное направление деятельности компании – разработка технологий и продуктов для автомобильного рынка. DFT размещает заказы на производство своих запчастей более чем на 60 заводах – производителях, территориально расположенных в Великобритании, Евросоюзе, Японии, Корее и КНР, которые поставляют свою продукцию на ОЕМ и вторичный рынок.",
    companyMiddleLeft: "Все собственные предприятия и заводы-партнеры компании DFT аккредитованы по стандартам менеджмента качества ISO 9001:2015 или ISO 9004:2009, а также отраслевому стандарту ISO/TS 16949:2009 для продуктов ОЕМ.",
    companyMiddleRight1: "При разработке и производстве запасных частей под брендом Double Force для своих потребителей, компания DFT руководствуется следующими принципами:",
    companyMiddleRight2: "Соответствие выпускаемой продукции международным стандартам качества",
    companyMiddleRight3: "Доступная для потребителя конечная стоимость продукта",
    companyMiddleRight4: "Разработка широкого ассортимента на все популярные модели автомобилей",
    companyMiddleBlue: "Основные товарные группы, выпускаемые под брендом Double Force:",
    companyAlliasTop1: "ДЕТАЛИ ТОРМОЗНОЙ СИСТЕМЫ",
    companyAlliasTop2: "ФИЛЬТРЫ",
    companyAlliasTop3: "ДЕТАЛИ ТРАНСМИССИИ",
    companyAlliasBottom1: "ДЕТАЛИ ПОДВЕСКИ",
    companyAlliasBottom2: "ЭЛЕКТРООБОРУДОВАНИЕ",
    companyAlliasBottom3: "ДЕТАЛИ СИСТЕМЫ ОХЛАЖДЕНИЯ",
    companyMiddleBottTitle: "ИСТОРИЯ",
    companyBottomLeft1: "История Double Force Technology берет свое начало в 60-х годах XIX века в городе Беркхамстед, графство Хартфордшир. В эти годы Беркхамстед был связующим звеном в растущей сети шоссе, каналов и железных дорог Англии.",
    companyBottomLeft2: "Изначально основанная под наименованием Raven's Lane Manufactory, компания специализировалась на оборудовании для строительства барж, необходимых для перемещения грузов по каналам британских островов. Бурное развитие сети железных дорог, а затем автомобильного транспорта изменило специализацию компании. С 1921 года компания Raven's Lane Manufactory получает название DFT, что расшифровывалось как Department For Transport.",
    companyBottomRight1: " Следуя за развитием технологий, создается исследовательско-производственное подразделение Double Force Technology, которое год за годом производит передовые продукты для автомобильного рынка - детали тормозной системы и системы охлаждения, детали трансмиссии и подвески, фильтры, электрооборудование. Штаб-квартира подразделения DFT, отвечающего за разработку автозапчастей, находится в Caterham, Surrey, UK",
    companyBottomLeft3: "Double Force Technology размещает заказы на производство своих запчастей более чем на 60 заводах-производителях в Великобритании, странах Евросоюза, Японии, Южной Корее и КНР. Все собственные предприятия и заводы-партнеры компании DFT аккредитованы по стандартам менеджмента качества ISO 9001:2015 или ISO 9004:2009, а также отраслевому стандарту ISO/TS 16949:2009 для продуктов ОЕМ. Предприятия используют единую инженерную базу данных DFT.",
    companyBottomRight2: "С июня 2017 года продукция Double Force стала доступна и для российских автомобилистов. Результатом длительного процесса переговоров стало заключение дистрибьюторского контракта с одним из лидеров российского рынка автозапчастей – компанией «Юником».",
    companyBottomRight3: "Первыми товарными группами, поставляемыми на российский рынок стали тормозные колодки и фильтры Double Force.",
    /** MOUNTS PAGE */
    mountsHeaderTitle: "Опоры Амортизаторов",
    mountsTopTitleLeft: "Производство",
    mountsTopTitleRight: "Вулканизация",
    mountsMiddleTitle: "Клей",
    mountsBottomTitleLeft: "Покраска",
    mountsBottomTitle: "Подшипники",
    mountsTopTextLeft: "Производят опоры по технологии сложнее в сравнении с оригинальными деталями, но оставляют полное соответствие конструкции.",
    mountsTopTextRight: "Эластомерная смесь проходит процесс вулканизации с добавлением стабилизаторов и пластификаторов.",
    mountsMiddleText: "Для надежного соединения резины с металлическими частями изделий используется клей на основе каучуков с добавками агента вулканизации, для усиления адгезивных свойств на поверхность металлических частей наносят праймер, происходит склеивание на молекулярном уровне, при котором идет взаимное проникновение клеевого состава и резиновой смеси.",
    mountsBottomTextLeft: "Изделия красят полимерными лакокрасочными материалами методом электроосаждения с получением антикоррозионного покрытия стойкого к сколам. Дополнительная антикоррозионная обработка увеличивает срок службы опор. ",
    mountsBottomText: "Подшипники изготовляются из высокоуглеродистой подшипниковой стали, корпус из термопластичного ударопрочного сополимера, внутренняя поверхность из полиамида, что повышает износоустойчивость, литиевую EP-смазку, которая снижает коэффициент трения.",
    /** ABSORBERS PAGE */
    absorbersHead: "Стойки и Амортизаторы",
    absorbersTitle1: "Особенности установки",
    absorbersTitle2: "Производство",
    absorbersTitle3: "Шток",
    absorbersTitle4: "Поршни",
    absorbersTitle5: "Масло",
    absorbersTitle6: "Лабораторная проверка <br> амортизаторов",
    absorbersText1: "Все амортизаторы Double Force имеют двухтрубную конструкцию, поэтому перед установкой их необходимо прокачать. Газа во внутреннем цилиндре (колбе) быть не должно. После прокачивания амортизаторов, все дальнейшие манипуляции с ними, в плоть до установки на автомобиль, осуществляются только штоком вверх.",
    absorbersText2: "Использование технологии электродуговой сварки на роботизированных кондукторах не нарушает внутреннюю поверхность корпуса стойки (трубы), при этом позволяет максимально надежно и крепко приваривать чашку к корпусу стойки. Сварка корпуса амортизатора с резинометаллическим шарниром выдерживает усилие разрыва не менее 20 кН.",
    absorbersText3: "Шток амортизатора покрыт хромом и отполирован для защиты от коррозии и наилучшего уплотнения. Проходит высокотемпературную закалку, высокочастотную обработку и шлифовку, что препятствует разрушению сальника. <br> Сальник производиться отдельно на заводе производства оригинальных запчастей nok",
    absorbersText4: "Изготавливаются из износостойких полимеров с антифрикционным покрытием, что препятствует износу и заклиниванию в экстремальных условиях.",
    absorbersText5: "Наличие мембраны внутри амортизирующего устройства позволяет разделить масло и воздух. Синтетическое масло с высоким индексом вязкости и отличной производительностью при низких температурах до -40 0С позволяет сохранить стабильные параметры амортизатора.",
    absorbersText6: "Лаборатория, имеющая аккредитацию в соответствии со стандартами ЕАЭС для тестирования автомобильных компонентов на предмет их соответствия требованиям, прописанным в ТР ТС 018/2011 и нормативам российского ГОСТ 34339-2017. Все стендовое оборудование, представленное в лаборатории, имеет аттестацию соответствующих надзорных органов на предмет соответствия метрологическим нормам.",
    absorbersText7: "Процесс лабораторного тестирования амортизатора включает в себя несколько операций:",
    absorbersText8: "Проверка герметичности", 
    absorbersText9: "Определение силовых характеристик",
    absorbersText10: "Проверка на предмет наличия шумов и иных посторонних звуков",
    absorbersText11: "Температурные испытания",
    absorbersText12: "Благодаря разработкам в области гидродинамики и демпфирования, амортизаторы позволяют лучше чем аналоги с той же ценой снизить вибрации и тряску на дороге",
    absorbersTitle13: "Широкий ассортимент SKU для разных типов <br> транспортных средств",
    absorbersTitle14: "Тщательное тестирование перед выпуском на <br> рынок",
    absorbersTitle15: "Высокое качество и надежность",
    /** AIR PAGE */
    airHead: "Воздушные<br>фильтры",
    airTitle1: "Среда и материалы",
    airTitle2: "Состав",
    airTitle3: "Качество",
    airText1: " Фильтрующая среда с классом горючести F1 или S1 и синтетическими пред очистителями не поддерживают огонь - само угасание пламени. <br> Фильтрующие материалы, созданные на основе целлюлозных или синтетических, микропористых перекрещенных волокон и обработаны синтетической смолой, что образует фиксированную матрицу пор.",
    airText2: "Фильтры оснащены эластичной уплотнительной резинкой для герметичного прилегания, ребрами жесткости и фиксацией шторы <br>Прокладки - смесь полиуретана гарантируют герметичность между чистой и грязной сторонами фильтра. <br>Гофры стабилизируются с помощью перфорированного покрытия, предотвращают слипание соседних складок.",
    airText3: "Компания Double Force произвела лабораторные испытания воздушного фильтра DFA2964, для оценки потребительских свойств в сравнении с конкурентами. Данный фильтр показал отличные результаты.",
    airListText1: "Высокомолекулярный эластичный состав с герметичностью соединения при температуре -50°С +150°С.",
    airListText2: "Фильтрующий элемент изготовлен на основе сложного комплекса целлюлозных и синтетических волокон.",
    airListText3: "Холоднокатаная сталь и оцинкованная крышка проходят термоформование или глубокой вытяжки.",
    airListText4: "Антикоррозийное полимерно – порошковое покрытие.", 
    airListText5: "Прецизионные клапаны.",
    airListText6: "Стальная центральная трубка с дополнительной внутренней жесткостью.",
    airListText7: "Увеличенная площадь фильтрующей поверхности.",
    airListText8: "Уплотнительное кольцо сделано из маслобензостойкой резины NBR.",
    airQualityText1: "Соответствуют стандартам ISO 5011 и ГОСТ 8002",
    airQualityText2: "Фильтры испытываются при давлении 13,5 атмосфер.",
    airQualityText3: "Сертифицированы по международному стандарту ISO/TS16949",
    airBenefitsText1: "Увеличенный ресурс",
    airBenefitsText2: "Особая пропитка предотвращает впитывание воды",
    airBenefitsText3: "Оптимальная геометрия складок",
    airBenefitsText4: "Полиуретан обеспечивает максимальную адгезию (сцепление)",
    airBenefitsText5: "Повышенная пылеемкость",
    airBenefitsText6: "Фильтры стойкие к деформации",
    /** BRAKE PAGE */
    brakeHeaderTitle: "ТОРМОЗНЫЕ <br> КОЛОДКИ",
    brakeTitle1: "Полное отсутствие шума и вибраций",
    brakeTitle2: "Низкий уровень пылеобразования",
    brakeTitle3: "Безопасность",
    brakeTitle4: "Безопасность для окружающей среды",
    brakeTitle5: "Долгий срок службы",
    brakeText1: "Уникальная трёхуровневая технология шумоподавления, включающая компьютерное моделирование геометрии колодки, нанесения прорезей и скосов на торцах изделия, обеспечивает непревзойдённый комфорт при торможении на любой скорости.",
    brakeText2: "Технология низкого пылеобразования существенно снижает отложение тормозной пыли на колёсных дисках. Использование наших тормозных колодок обеспечивает меньший износ тормозного диска, который является главной причиной образования тёмной пыли.",
    brakeText3: "Высокое останавливающее усилие тормозных колодок Double Force гарантирует короткий тормозной путь вашему автомобилю. Высокий отклик при резком торможении обеспечивает максимальную безопасность при движении на высоких скоростях. Вы будете уверены в своих тормозах, вне зависимости от того, совершаете ли вы обыденные поездки по городу или же путешествуете по загородному шоссе.",
    brakeText4: "Особый состав фрикционного материала отличается повышенной стабильностью свойств и обеспечивает высокий коэффициент трения при любых температурах, что делает торможение предсказуемым и безопасным. Усовершенствованная технология склейки гарантирует надёжную фиксацию фрикционного материала на металлической основе колодки.",
    brakeText5: "Срок службы наших тормозных колодок превышает отраслевые стандарты для легкового автотранспорта. Неагрессивны к тормозному диску.",
    brakeText6: "Наши тормозные колодки сертифицированы на соответствие строгим нормам Европейских, Американских и международных стандартов.",
    brakeText7: "Тормозные колодки Double Force не содержат асбеста, тяжёлых металлов и иных вредных для человека или окружающей среды материалов.",
    brakeText8: "Фрикционный материал имеет больший ресурс и увеличивает общий межсервисный интервал.",
    brakeText9: "С целью сравнения потребительских свойств тормозных колодок Double Force с конкурентами на Российском рынке были проведены лабораторные исследования. Результаты по скорости расходования колодок, износу диска, прочности соединения фрикционных накладок - ниже.",
    brakeText10: "*износ диска количественно определить не представилось возможным, так как разница в измерениях до и после испытаний находилась в пределах точности измерений мерительного инструмента",
    brakeText11: "FADE - тест измеряющий эффективность кололодок при нагреве. Результаты измерения коэффициента трения в зависимости от температуры и других условий будут сведены чуть позже.",
    brakeBenefitsText1: "Качество оригинальной продукции",
    brakeBenefitsText2: "Все тормозные колодки проходят проверку на испытательных стендах, что гарантирует их надежность и долговечность",
    brakeBenefitsText3: "Благодаря высокой автоматизации производства, компания обеспечивает высокую точность размеров и формы тормозных колодок",
    brakeBenefitsText4: "Соответствие стандарту ECE R-90, который отвечает в том числе и за экологичность, а также повышенная износостойкость и улучшенные эксплуатационные характеристики",
    /** ACPP PAGE */
    acppHeaderTitle: "Фильтры для АКПП",
    acppText1: "Ассортимент трансмиссионных фильтров Double Force покрывает более 90% автомобилей японских марок оснащённых автоматической и бесступенчатой трансмиссией.",
    acppText2: "Трансмиссионные фильтры Double Force укомплектованы необходимыми прокладками и уплотнителями.",
    acppText3: "Трансмиссионные фильтры Double Force производятся на современных мощностях в соответствии с мировыми стандартами.",
    acppText4: "Качество фильтров контролируется на каждом этапе и полностью соответствует требованиям к оригинальной продукции.",
    acppText5: "Продукция поставляется на рынки Европы, Северной Америки и Азии.",
    acppText6: "Трансмиссионные фильтры Double Force обеспечивают непревзойдённое качество фильтрации и надёжно защищают внутренние элементы трансмиссии от загрязнений и продуктов износа.",
    acppText7: "Благодаря использованию высококачественных материалов трансмиссионные фильтры Double Force отличаются высоким ресурсом и сохраняют отличные характеристики фильтрации в течение длительного времени.Ресурс трансмиссионных фильтров Double Force превышает рекомендованный автопроизводителями межсервисный интервал замены трансмиссионной жидкости.",
    acppText8: "Вся продукция проходит тщательный контроль качества на всех этапах производства.",
    acppText9: "Дополнительные испытания фильтров в лабораториях РФ подтверждают высокое качество и гарантируют безопасность трансмиссионных фильтров Double Force.", 
    acppTitle1: "Производство мирового уровня",
    acppTitle2: "Высокие потребительские свойства",
    acppTitle3: "Высокий ресурс",
    acppTitle4: "Тотальный контроль качества",
    acppBenefitsText1: "Минимальные технологические допуски обеспечивают <br> <span style='font-weight: 700'>низкое трение</span>  и, за счет этого, <span style='font-weight: 700'>высокий КПД</span>",
    acppBenefitsText2: "<span style='font-weight: 700'>Высокая эффективность</span>и максимальный срок службы",
    acppBenefitsText3: "Система <span style='font-weight: 700'>100% контроля качества</span> технологического процесса.",
    /** SHRUS PAGE */
    shrusHeaderTitle: "Шрусы",
    shrusTitle1: "Производство",
    shrusTitle3: "Лабораторные испытания",
    shrusText1: "Детали ШРУСов изготавливаются из легированной стали с термальной обработкой высокочастотными токами и применением технологий закалки металла. <br>ШРУСы работают при температуре окружающего воздуха -40 +50 °С.",
    shrusText2: "ШРУСы комплектуются - фирменной молибденовой смазкой BP, стопорным кольцом, защитным чехлом с двумя металлическими хомутами и крепежной гайкой ступицы. <br>Смазка производиться c дисульфидом молибдена, что делает смазку высокотемпературной, водостойкой и консистентной. Плюсом добавляется антифрикционные и антикоррозийные присадки. <br>Пыльники производят на основе резиновой смеси из натурального каучука стойкого к погодным условиям, перепадам температур, попаданию химии и реагентов.",
    shrusText3: "Проверка на выходе по 11 параметрам в автоматизированной тестовой лаборатории: качество сборки, геометрия, запас по крутящему моменту и т.д. <br>Соответствуют требованиям стандартов GB/T308-2002, DIN5401/, ISO/TS 16949 ГОСТ Р ИСО/ТУ 16949-2009.",
    shrusBenefitsText1: "В наружных ШРУСах применена классическая конструкция сепаратора с шариковыми подшипниками",
    shrusBenefitsText2: "Внутренние ШРУСы имеют современную трипоидную конструкцию",
    shrusBenefitsText3: "Детали шарниров изготовлены из легированной конструкционной стали и подвергнуты термообработке токами высокой частоты",
    shrusBenefitsText4: "Все ШРУСы проходят химическую обработку с целью увеличения сопротивляемости износу и антикоррозионной защиты. Наносимая при этом смазка также помогает снизить уровень шума и улучшить качество работы ШРУСа.",
    /** CABIN PAGE */
    cabinHeaderTitle: "Салонные <br/> фильтры",
    cabinTitle1: "Надежный барьер",
    cabinTitle2: "Подходит для большинства автомобилей",
    cabinTitle3: "Фильтровальный элемент от мирового лидера",
    cabinTitle4: "Комфортное дыхание и защита здоровья",
    cabinTitle5: "Высокая производительность",
    cabinTitle6: "Пролонгированный срок эффективного использования",
    cabinText1: "Чистый воздух в салоне автомобиля - залог хорошего настроения и комфорта в поездке. Старый салонный фильтр создает немало проблем. Пыль, гарь, неприятные запахи, аллергены раздражают глаза и органы дыхания, быстро утомляют водителя и пассажиров. Загрязнения накапливаются в салоне автомобиля, в итоге превышая уличные показатели в пятикратном размере. Запотевшие окна мешают обзору. Чтобы избежать этих проблем, меняйте салонный фильтр на DOUBLE FORCE. Продукция британской компании DFT отличается идеальной совместимостью, привлекательной ценой и гарантированным качеством.",
    cabinText2: "Эффективность очистки от механических частиц - более 96%",
    cabinText3: "Ассортимент салонных фильтров DOUBLE FORCE охватывает большинство автомобилей европейских, японских и корейских марок.",
    cabinText4: "В салонных фильтрах DOUBLE FORCE используются компоненты от ведущих мировых поставщиков. Это гарантирует полное сохранение технических характеристик фильтров DOUBLE FORCE на протяжении всего срока их службы.",
    cabinText5: "Стандартные салонные фильтры DOUBLE FORCE эффективно борются с загрязнениями размером до 8 микрон. За это отвечает композитный фильтрующий элемент на основе нейлона и хлопка от компании 3М. Стандартные салонные фильтры DOUBLE FORCE защитят вас от пыли, сажи, пыльцы, спор плесени.",
    cabinText6: "Низкое сопротивление потоку воздуха салонных фильтров DOUBLE FORCE позволяет отфильтровать больший объем воздуха за единицу времени.",
    cabinText7: "Применение в салонных фильтрах DOUBLE FORCE нетканого синтетического материала позволяют нам нежно заботиться о здоровье водителя и пассажиров длительный срок. Меняйте салонные фильтры на продукцию DOUBLE FORCE раз в год или каждые 15000 км пробега.",
    cabinListText1: "Антибактериальная пропитка",
    cabinListText2: "Электростатический заряд",
    cabinListText3: "Синтетический нетканный материал на основе нейлона и хлопка",
    cabinListText4: "Двойная структура с механическим способом фильтрации",
    cabinListText5: "Точная посадка исключает «подсос» воздуха",
    /** HUBS PAGE */
    hubsHeaderTitle: "Ступицы <br/> и ступичные узлы",
    hubsTitle1: "Жесткость и прочность",
    hubsTitle3: "Лабораторные испытания",
    hubsText1: "Сепараторы, изготовленные из полиамида, дополнительно усилены стекловолокном, что увеличивает жесткость и прочность, гарантирует равномерное распределение тел качения и исключает заедание.",
    hubsText2: "Автоматизированная селективная подборка тел качения гарантирует равномерное распределение нагрузки, снижение вибраций и потерь трения, повышает стабильность виброакустических характеристик, возникающих при работе подшипника.",
    hubsText3: "Поверхностная индукционная закалка дорожек качения увеличивает твердость, износостойкость и устойчивость к ударным нагрузкам тел.",
    hubsText4: "Пластичная литиевая EP-смазка, сохраняющая свойства в температурном диапазоне -40 +180 °C.",
    hubsText5: "Конструкция сальника защищает ступичный узел от внешних загрязнений, удерживает смазку внутри подшипника и устойчив к износу в неблагоприятной среде.",
    hubsText6: "Болты обработаны сверхустойчивым цинко-алюминиевым покрытием, надежно защищающим от коррозии на протяжении срока эксплуатации.",
    hubsText7: "Встроенные датчики и магнитные энкодеры изготовлены по строгим международным стандартам и гарантируют чёткую работу ABS для безопасности водителя и пассажиров при движении автомобиля.",
    hubsText8: "Ступицы, выпускаемые в комплекте с подшипниками, испытываются на испытательном стенде, где имитируется вращение колеса вокруг оси. Измеряется полученный люфт и делается вывод о том, пригоден ли узел ступицы колеса к использованию.· Каждая ступица колеса тестируется на микрометрическом оборудовании, проверяя соответствие размеров ступицы, требуемым размерам. В результате детали с неправильной геометрией отбраковываются, а вибрации в тормозном механизме исключаются.",
    hubsBenefitsText1: "Ассортимент представлен <span style='font-weight: 700'>большим количеством</span> моделей",
    hubsBenefitsText2: "Ступицы производятся с использованием <span style='font-weight: 700'>передовых технологий</span>", 
    hubsBenefitsText3: "Упор <span style='font-weight: 700'>на простую установку</span> изделия, поэтому установка ступицы <span style='font-weight: 700'>не займет много времени</span>",
    hubsBenefitsText4: "<span style='font-weight: 700'> Адаптация</span> к российским условиям",
    /** FUEL PAGE */
    fuelHeaderTitle: "Модульные топливные фильтры",
    fuelText1: "Модульные топливные фильтры - это инновационная разработка, позволяющая обеспечить чистоту и качество топлива, поступающего в двигатель автомобиля. Эти фильтры играют важную роль в предотвращении попадания вредных примесей, грязи и микрочастиц в систему питания автомобиля.",
    fuelText2: "Принцип работы топливных фильтров основан на пропускании топлива через специальный фильтрующий материал. Этот материал обладает мельчайшими порами, которые задерживают примеси и частицы, не позволяя им попасть в систему питания двигателя. Таким образом, обеспечивается чистота и качество топлива.",
    fuelTitle1: "Высокая эффективность фильтрации",
    fuelTitle2: "Продолжительный срок службы",
    fuelTitle3: "Защита от поломок",
    fuelText3: "Обладают высокой степенью фильтрации и задерживают даже самые мелкие частицы, что способствует улучшению качества топлива. Это позволяет снизить износ двигателя и увеличить его срок службы.",
    fuelText4: "Благодаря использованию прочного и высококачественного материала, топливные фильтры обеспечивают длительную работу без потери своих свойств и эффективности.",
    fuelText5: "Предотвращают попадание грязи и мелких частиц в систему питания двигателя, что помогает предотвратить поломки и дорогостоящий ремонт.",
    /** COILS PAGE */
    coilsHeaderTitle: "Катушки <br/>зажигания",
    coilsTitle1: "Особенности производства",
    coilsTitle3: "Качество",
    coilsText1: "­Катушки зажигания DOUBLE FORCE – ОЕМ качество продукции. Продукция включает в себя ассортимент на европейские, американские, японские и корейские автомобили. Количество наименований в ассортименте - более 150 позиций, которые подходят для автомобилей разных типов.",
    coilsText2: "Меньшее количество производственных операций, меньшая металлоёмкость и трудоёмкость производства, что отражается на конечной цены изделия.",
    coilsText3: "Изоляционный материал катушек не допускает появления пузырьков в материале.",
    coilsText4: "Эмалированная проволока - стойкая к температурам до 180 °С, в то время как не эмалированная проволока до 140 °С.",
    coilsText5: "Шпуля вторичной обмотки выдерживает к напряжению 33 KV/MM против обычного пластика 20 KV/ MM.",
    coilsText6: "Электронный модуль - печатная плата толщиной 1,2 мм с повышенной механической прочностью и электроизоляционными свойствами.",
    coilsText7: "Провода увеличенной толщины с повышенной производительностью.", 
    coilsText8: "Вывод первичной обмотки, вторичной обмотки, другие электрические компоненты, провода на безопасном расстоянии, что исключает электропомехи.",
    coilsText9: "Сердечник - используется материал с гладкой поверхностью для увеличения производительности.",
    coilsText10: "Качество продукции определяется сертификатами качества: TUV, TS 16949, ISO 9001.",
    /** OIL PAGE */
    oilsHeaderTitle: "Масляные <br/>фильтры",
    oilsTitle1: "Особенности производства",
    oilsText1: "Масляный фильтр DOUBLE FORCE обеспечивает чистоту масла в двигателе, тем самым, предупреждает преждевременное старение и износ контактирующих деталей.",
    oilsText2: "Системы фильтрации созданы на основе требований OEM и превосходят их по ряду параметров — некоторые составляющие фильтров имеют повышенный запас прочности для обеспечения надежной работы автомобиля.",
    oilsText3: " Основа производства систем фильтрации - исследования, испытания, а также, использование передовых технологий.",
    oilsText4: "Высочайшая степень очистки от загрязнений — до 99% подтверждена тестами, проведенными по стандарту ISO 4548-12 ",
    oilsText5: "Точно настроенный перепускной клапан (байпас) обеспечивает равномерный поток масла в системе даже при его высокой вязкости и выработке ресурса фильтра.",
    oilsText6: "Продукция соответствует стандартам трех наиболее требовательных ассоциаций в сфере стандартизации и качества: SAE (США), JIS (Япония) и DIN (Германия) и сертифицирована по стандарту ISO / TS 16949, ISO 17025, ISO 14001 ",
    oilsText7: "Высокая надёжность и эффективность масляных фильтров DOUBLE FORCE обеспечивается прочной конструкцией корпуса фильтра, правильной конструкцией предохранительного перепускного и противодренажного клапана, использованием устойчивого к пакетированию нового фильтрующего материала на основе композитов.",
    oilsText8: "Особая формовка и глубокое гофрирование фильтрующего материала обеспечивают большую площадь фильтрования и исключают проникновение неочищенного масла к деталям двигателя. Высокая прочность корпусных элементов, фильтр-элемента и соединений (вальцовка с использованием уплотнительной прокладки) защищает фильтр от разрушения при резких перепадах давления в масляной системе или механических воздействий при неправильной установке.",
    oilsText9: "Заводские испытания — 9 часов при t -40℃ и давлением 9 бар — подтверждают, что масляные фильтры имеют повышенный запас прочности, превосходящий требования OEM.",
    /** SPRINGS PAGE */
    springsHeaderTitle: "ПРУЖИНЫ<br>ПОДВЕСКИ",
    springsTitle1: "Холодная навивка пружин на автомате",
    springsTitle2: "Электростатический способ окрашивания",
    springsTitle3: "Легирование хромом и кремнием",
    springsTitle4: "Дробеструйная обработка пружин",
    springsTitle5: "Осадка",
    springsTitle6: "Многоэтапная низкотемпературная термическая обработка",
    springsTitle7: "Цинковое фосфатирование пружин",
    springsTitle8: "Порошковая покраска пружин",
    springsText1: "Пружинный элемент скручивают в холодном состоянии при помощи специальных агрегатов, бескулачковых станков-мультиформеров.",
    springsText2: "Позволяет доставить материал в труднодоступные места, обеспечить равномерное покрытие слоем, избежать дефектов.",
    springsText3: "Хром повышает устойчивость к коррозии, электрическое сопротивление и прочность. Кремний увеличивает жаростойкость и твердость.",
    springsText4: "В свободном состоянии повышает предел выносливости пружин на 80%, а в статически напряженном состоянии— на 92%.",
    springsText5: "Трехкратное полное сжатие пружины – что повышает упрочняющий эффект.",
    springsText6: "В свободном состоянии повышает предел выносливости пружин на 80%, а в статически напряженном состоянии — на 92%.", 
    springsText7: "Увеличение временного сопротивления при растяжении, пределов упругости и текучести при кручении. Число перегибов в результате ее фосфатирования уменьшается.", 
    springsText8: "Способ получения полимерных покрытий, которые обладают защитными свойствами, предохраняя от механических повреждений.",
    springsBenefitsText1: "<strong style='font-weight: 700;'>Минимальный процент брака</strong>, так как каждая запчасть проходит жесткий контроль качества",
    springsBenefitsText2: "Пружины имеют сложное <strong style='font-weight: 700;'>мультислойное защитное поверхностное нанесение</strong> , которое имеет полимерную грунтовку. Это особое покрытие полностью обеспечивает <strong style='font-weight: 700;'>высокую защиту поверхности пружин</strong> от коррозии и появления разрушений в местах ударов камней и гравия.",
    springsBenefitsText3: "<strong style='font-weight: 700;'>Технология боковой нагрузки</strong> пружины <strong style='font-weight: 700;'>продлевает срок службы</strong> элементов подвески",
    /** menu */
    menu: 'Меню',
    menuMain: 'Главная',
    menuHome: "Double Force",
    menuCatalog: "Каталог",
    menuInform: "Информация",
    menuWhereToBuy: "Где купить",
    menuAssurance: "Гарантия",
    menuPartners: "Партнерам",
    menuAbout: "Компания",
    menuSelection: "Подбор деталей",
    /** modal feedback marketplace */
    modalFeedback: "Обратная связь",
    marketplaceTitle: "Выберите маркетплейс",
    marketplaceLinkRetail: "Розничные магазины на карте",
    /** catalog item */
    catalogWiper: "Щетки стеклоочистителя",
    catalogCoils: "Катушки зажигания",
    catalogAbsorbers: "Амортизаторы",
    catalogMounts: "Опоры амортизаторов",
    catalogAir: "Воздушные фильтры",
    catalogFuel: "Модульные топливные фильтры",
    catalogCabin: "Салонные фильтры",
    catalogHubs: "Ступицы и ступичные узлы",
    catalogBrake: "Тормозные колодки",
    catalogAcpp: "Фильтры для АКПП",
    catalogShruses: "Шрусы",
    catalogSprings: "Пружины подвески",
    catalogOil: "Масляные фильтры",
    /** main-slogan */
    homeSlogan1: "Качество",
    homeSlogan2: "Приемлемая",
    homeSlogan3: "цена",
    homeSlogan4: "Удобство", 
    homeSlogan5: "Комплектация",
    /** store */
    storeRefText: 'Приобретай продукцию в интернете или в магазинах своего города',
    storeRefLink: 'На карте',
   












    /** catalog/cabinFilters */
    cabinHead: "Салонные <br> фильтры",
    cabinHeadLg: "Салонные фильтры",
    cabinText1: "Чистый воздух в салоне автомобиля - залог хорошего настроения и комфорта в поездке. Старый салонный фильтр создает немало проблем. Пыль, гарь, неприятные запахи, аллергены раздражают глаза и органы дыхания, быстро утомляют водителя и пассажиров. Загрязнения накапливаются в салоне автомобиля, в итоге превышая уличные показатели в пятикратном размере. Запотевшие окна мешают обзору. Чтобы избежать этих проблем, меняйте салонный фильтр на DOUBLE FORCE. Продукция британской компании DFT отличается идеальной совместимостью, привлекательной ценой и гарантированным качеством.",
    cabinText2: "Надежный барьер",
    cabinText3: "Эффективность очистки от механических частиц - более 96%",
    cabinText4: "Подходит для большинства автомобилей",
    cabinText5: "Ассортимент салонных фильтров DOUBLE FORCE охватывает большинство автомобилей европейских, японских и корейских марок.",
    cabinText6: "Фильтровальный элемент от мирового лидера",
    cabinText7: "В салонных фильтрах DOUBLE FORCE используются компоненты от ведущих мировых поставщиков. Это гарантирует полное сохранение технических характеристик фильтров DOUBLE FORCE на протяжении всего срока их службы.",
    cabinText8: "Комфортное дыхание и защита здоровья",
    cabinText9: "Стандартные салонные фильтры DOUBLE FORCE эффективно борются с загрязнениями размером до 8 микрон. За это отвечает композитный фильтрующий элемент на основе нейлона и хлопка от компании 3М. Стандартные салонные фильтры DOUBLE FORCE защитят вас от пыли, сажи, пыльцы, спор плесени.",
    cabinText10: "Высокая производительность",
    cabinText11: "Низкое сопротивление потоку воздуха салонных фильтров DOUBLE FORCE позволяет отфильтровать больший объем воздуха за единицу времени.",
    cabinText12: "Пролонгированный срок эффективного использования",
    cabinText13: "Применение в салонных фильтрах DOUBLE FORCE нетканого синтетического материала позволяют нам нежно заботиться о здоровье водителя и пассажиров длительный срок. Меняйте салонные фильтры на продукцию DOUBLE FORCE раз в год или каждые 15000 км пробега.",
    cabinText14: "Антибактериальная пропитка",
    cabinText15: "Электростатический заряд",
    cabinText16: "Синтетический нетканный материал на основе нейлона и хлопка",
    cabinText17: "Двойная структура с механическим способом фильтрации",
    cabinText18: "Точная посадка исключает «подсос» воздуха",
    cabinTextBuy: "Купить на маркетплейсах",


    /** catalog/suspension */
    suspensionHead: "ПРУЖИНЫ<br>ПОДВЕСКИ",
    suspensionText1: "Холодная навивка пружин на автомате",
    suspensionText2: "Пружинный элемент скручивают в холодном состоянии при помощи специальных агрегатов, бескулачковых станков-мультиформеров.",
    suspensionText3: "Электростатического способ окрашивания",
    suspensionText4: "Позволяет доставить материал в труднодоступные места, обеспечить равномерное покрытие слоем, избежать дефектов.",
    suspensionText5: "Легирование Хромом и Кремнием",
    suspensionText6: "Хром повышает устойчивость к коррозии, электрическое сопротивление и прочность. Кремний увеличивает жаростойкость и твердость.",
    suspensionText7: "Дробеструйная обработка пружин",
    suspensionText8: "В свободном состоянии повышает предел выносливости пружин на 80%, а в статически напряженном состоянии— на 92%.",
    suspensionText9: "Осадка",
    suspensionText10: "Трехкратное полное сжатие пружины – что повышает упрочняющий эффект.",
    suspensionText11: "Многоэтапная низкотемпературная термическая обработка",
    suspensionText12: "В свободном состоянии повышает предел выносливости пружин на 80%, а в статически напряженном состоянии— на 92%.",
    suspensionText13: "Цинковое фосфатирование пружин",
    suspensionText14: "Увеличение временного сопротивления при растяжении, пределов упругости и текучести при кручении. Число перегибов в результате ее фосфатирования уменьшается.",
    suspensionText15: "Порошковая покраска пружин",
    suspensionText16: "Способ получения полимерных покрытий, которые обладают защитными свойствами, предохраняя от механических повреждений.",
    suspensionText17: "Преимущества",
    suspensionUl1: "Минимальный процент брака",
    suspensionUl2: ", так как каждая запчасть проходит жесткий контроль качества",
    suspensionUl3: "Пружины имеют сложное",
    suspensionUl4: "мультислойное защитное поверхностное нанесение",
    suspensionUl5: ", которое имеет полимерную грунтовку. Это особое покрытие полностью обеспечивает",
    suspensionUl6: "высокую защиту поверхности пружин",
    suspensionUl7: "от коррозии и появления разрушений в местах ударов камней и гравия",
    suspensionUl8: "Технология боковой нагрузки",
    suspensionUl9: "пружины",
    suspensionUl10: "продлевает срок службы",
    suspensionUl11: "элементов подвески",
    suspensionButton: "Купить на маркетплейсах",
    
    shrusesHead: "Шрусы",
    shrusesText1: "производство",
    shrusesText2: "Детали ШРУСов изготавливаются из легированной стали с термальной обработкой высокочастотными токами и применением технологий закалки металла.<br>ШРУСы работают при температуре окружающего воздуха -40 +50 °С",
    shrusesText3: "КОМПЛЕКТАЦИЯ",
    shrusesText4: "ШРУСы комплектуются - фирменной молибденовой смазкой BP, стопорным кольцом, защитным чехлом с двумя металлическими хомутами и крепежной гайкой ступицы.<br>Смазка производиться c дисульфидом молибдена, что делает смазку высокотемпературной, водостойкой и консистентной. Плюсом добавляется антифрикционные и антикоррозийные присадки.<br>Пыльники производят на основе резиновой смеси из натурального каучука стойкого к погодным условиям, перепадам температур, попаданию химии и реагентов.",
    shrusesText5: "Лабораторные испытания",
    shrusesText6: "Проверка на выходе по 11 параметрам в автоматизированной тестовой лаборатории: качество сборки, геометрия, запас по крутящему моменту и т.д.<br>Соответствуют требованиям стандартов GB/T308-2002, DIN5401/, ISO/TS 16949 ГОСТ Р ИСО/ТУ 16949-2009·",
    shrusesText7: "Преимущества",
    shrusesText8: "В наружных ШРУСах применена классическая конструкция сепаратора с шариковыми подшипниками",
    shrusesText9: "Внутренние ШРУСы имеют современную трипоидную конструкцию",
    shrusesText10: "Детали шарниров изготовлены из легированной конструкционной стали и подвергнуты термообработке токами высокой частоты",
    shrusesText11: "Все ШРУСы проходят химическую обработку с целью увеличения сопротивляемости износу и антикоррозионной защиты. Наносимая при этом смазка также помогает снизить уровень шума и улучшить качество работы ШРУСа.",
    shrusesText12: "Купить на маркетплейсах",

    /** catalog/wiper */
    wiperHead: "ЩЕТКИ СТЕКЛО-<br>ОЧИСТИТЕЛЕЙ",
    wiperText1: "ЩЕТКИ СТЕКЛООЧИСТИСТЕЛЕЙ",
    wiperText2: "Сертифицировано по международному менеджменту качества ISO/TS 16949.",
    wiperText3: "Технология многоэтапной окраски и применение шарнирных соединений с независимыми точками опоры сводит на нет случайные поломки или возможность коррозии.",
    wiperText4: "Полные комплекты всех распространенных видов крепления в каждой щетке.",
    wiperText5: "Цена — одна из самых низких на рынке в эконом-сегменте.",
    wiperText6: "Каркасные (Стандартные)",
    wiperText7: "Цельнометаллический, оцинкованный корпус.",
    wiperText8: "Равномерное распределение точек соприкосновения.",
    wiperText9: "Износостойкая резина лезвия щёток.",
    wiperText10: "Низкопрофильные, термопластовые вставки в местах сопряжения коромысел предотвращают образование наледи, обеспечивая отличную производительность.",
    wiperText11: "Способ крепления №1",
    wiperText12: "Способ крепления №2",
    wiperText13: "бесКаркасные",
    wiperText14: "Отсутствие открытых металлических элементов.",
    wiperText15: "Плоский профиль.",
    wiperText16: "Пружинные полосы из высокотехнологичной стали обеспечивают постоянное высокое давление по всей длине дворников.",
    wiperText17: "Аэродинамическая, обтекаемая, монолитная конструкция, снижающая давление и шумовое воздействие воздуха и обеспечивающая максимальный прижим на высоких скоростях.",
    wiperText18: "Способы крепления:",
    wiperText19: "ГИБРИДНЫЕ ЩЕТКИ",
    wiperText20: "Интегрирования аэродинамическая конструкция с резиновым полотном, с NANO-Graphit (жидким графитовым) покрытием и прецизионно обрезанной кромкой сохраняет свои свойствах при температурах от -30С до +50С.",
    wiperText21: "Полностью закрытый стальной корпус и низкопрофильный дизайн",
    wiperText22: "Пружина из нержавеющей стали для оптимального давления на стекло",
    wiperText23: "Способы крепления:",
    wiperText24: "ЗИМНИЕ ЩЕТКИ",
    wiperText25: "Водонепроницаемый, герметичный чехол",
    wiperText26: "Увеличенный в объёме профиль лезвия стеклоочистителя",
    wiperText27: "Форма и материал каучуковых щёток разработаны с учётом холодных зим, и не обмерзают",
    wiperText28: "Аэродинамические складки каучука работают на сжатие в середине хода дворника, что сохраняет эластичность при температуре до -40 ºС.",
    wiperText29: "Малошумные при любой степени износа",
    wiperText30: "Сталь с антикоррозийным покрытием",
    wiperText31: "КРЕПЛЕНИЯ В КОМПЛЕКТ",
    wiperText32: "Купить на маркетплейсах",
    wiperText33: "РАЗМЕРНАЯ ЛИНЕЙКА",
    wiperText34: "Зимние щётки",
    wiperText35: "Артикул",
    wiperText36: "Каркасные щётки",
    wiperText37: "Бескаркасные щётки",
    wiperText38: "Гибридные щётки",

    header1: "Компания",
    header2: "Партнерам",
    header3: "Каталог",
    header4: "Гарантия",
    header5: "Где купить",

    listHome1: "Широкий ассортимент Double Force представлен аналогами ОЕМ запчастей для автомобилей старых годов выпуска до последних моделей.",
    listHome2: "Все группы",
    
};
