<template>
    <div class="language-bar">
        <button class="buttonLang" @click="updateLocale(active)">
            <p v-html="locale.toUpperCase()"></p>
            <img src="@/assets/img/png/header/ru.png" alt="ru.png" v-if="locale==='en'">
            <img src="@/assets/img/png/header/en.png" alt="en.png" v-if="locale==='ru'">
        </button>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const { locale, t } = useI18n();
const route = useRoute();
const router = useRouter();

const active = computed(() => {
    return locale.value === 'ru' ? 'en' : 'ru';
});

function updateLocale(lang) {
    localStorage.setItem("locale", lang);
    window.location.href = router.resolve({
        name: route.name,
        query: route.query,
        hash: route.hash,
        params: { locale: lang },
    }).fullPath;

    if (route.meta.titleI18n && Object.keys(route.params).length == 0) {
        document.title = t(route.meta.titleI18n);
    }
}

</script>

<style lang="sass" scoped>
@import '@stylebase/media.sass'
.language-bar
    display: flex
    align-items: center
    flex-direction: row
    margin-left: 40px
    @include xl
        margin-left: 10px
    @include md
        margin: 0
    
.buttonLang
    border: none
    background: transparent
    display: flex
    flex-direction: row
    align-items: center
    column-gap: 10px
    cursor: pointer
</style>