<template>
    <div class="comeback">
        <div class="comeback-block" :class="{'comeback-block_visible' : comebackVisible }">
            
            <a class="comeback-block__button" @click="scrollWin">
                <img src="@/assets/img/png/arrow-up.png" alt="arrow-up.png" class= "comeback-block__button-img">
                
            </a>
            
        </div>
    </div>
   
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
const comebackVisible = ref(null)

function scrollWin() {  
    window.scrollTo(0, 0)
}

const scrollForHeader = () => {
    comebackVisible.value = window.scrollY > 500;
}
onMounted(() => {
    // console.log(route)
    window.addEventListener('scroll', scrollForHeader)
})
onUnmounted(() => {
    window.removeEventListener('scroll', scrollForHeader)
})

</script>


<style lang="sass" scoped>
@import '@stylebase/variables.sass'
@import '@stylebase/media.sass'

.comeback
    // position: relative
    // display: flex
    // max-width: 1920px
    // margin-left: auto
    // margin-right: auto
    position: sticky
    bottom: 0
    display: flex
    justify-content: end
    height: 0
    z-index: 90
.comeback-block
    
    display: none
    border-radius: 50%
    width: 54px
    height: 54px
    cursor: pointer
 
    &_visible
        display: block
        right: 60px
        bottom: 280px
        z-index: 90
        opacity: 0.4
        position: relative
        // display: block
        // position: fixed
        // right: 60px
        // bottom: 100px
        // z-index: 1000
        // opacity: 0.5
        @include lg
            right: 20px
        @include md
             bottom: 240px
    &_visible:hover
        opacity: 1
        transition: .35s ease-out
        transform: translateY(-3px)
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2)
    &__button
        position: absolute
        width: 100%
        
</style>